import { memo } from 'react';

import { Table } from 'semantic-ui-react';

import { MoneyWithUserCurrency } from '../../../../components/table-components/Money';
import { TableCellPlayerInfo } from '../../../../components/table-components/TableCellPlayerInfo';
import { IPlayersRow } from '../../model/types';

export const PlayersRow = memo<IPlayersRow>(({
  bet,
  showLifetime,
}) => {
  const {
    bets,
    margin,
    turnover,
    turnoverUSD,
    profit,
    profitUSD,
    payout,
    payoutUSD,
    user
  } = bet;

  const {
    currency,
    firstName,
    id,
    lastName,
    username,
    origin,
    userFactorGroup,
    performance,
    overrideLimits
  } = user;

  return (
    <Table.Row>
      <Table.Cell className="number-table-cell">
        <TableCellPlayerInfo
          firstName={firstName}
          lastName={lastName}
          username={username}
          id={id}
          origin={origin.id}
          userFactorGroupId={userFactorGroup.id}
          parentDomId="settled-by-players--"
          overrideLimits={overrideLimits}
        />
      </Table.Cell>
      <Table.Cell className="number-table-cell">
        {bets}
        {showLifetime && ` / ${performance?.totalBets || 0}`}
      </Table.Cell>
      <Table.Cell className="number-table-cell">
        <MoneyWithUserCurrency usd={turnoverUSD} currency={currency}>
          {turnover}
        </MoneyWithUserCurrency>
        {showLifetime && (
          <>
            {' /'}{' '}
            <MoneyWithUserCurrency usd={performance?.totalTurnoverUSD || 0} currency={currency}>
              {performance?.totalTurnover || 0}
            </MoneyWithUserCurrency>
          </>
        )}
      </Table.Cell>
      <Table.Cell className="number-table-cell">
        <MoneyWithUserCurrency usd={payoutUSD} currency={currency}>
          {payout}
        </MoneyWithUserCurrency>
        {showLifetime && (
          <>
            {' /'}{' '}
            <MoneyWithUserCurrency usd={performance?.totalPayoutUSD || 0} currency={currency}>
              {performance?.totalPayout || 0}
            </MoneyWithUserCurrency>
          </>
        )}
      </Table.Cell>
      <Table.Cell className="number-table-cell">
        <MoneyWithUserCurrency usd={profitUSD} currency={currency}>
          {profit}
        </MoneyWithUserCurrency>
        {showLifetime && (
          <>
            {' /'}{' '}
            <MoneyWithUserCurrency usd={performance?.totalProfitUSD || 0} currency={currency}>
              {performance?.totalProfit || 0}
            </MoneyWithUserCurrency>
          </>
        )}
      </Table.Cell>
      <Table.Cell className="number-table-cell">
        {margin}%{showLifetime && ` / ${performance?.totalMargin || 0}%`}
      </Table.Cell>
    </Table.Row>
  );
});
