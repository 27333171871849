import { useEffect, useMemo, useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';

import {
  fetchBets,
  fetchEvent,
  fetchEventPathTree,
  fetchLeagueById,
  fetchLeagues,
  fetchLimitGroups,
  fetchMarket,
  fetchMarkets,
  fetchMarketType,
  fetchOpponents,
  fetchOriginMargins,
  fetchPeriod,
  fetchPersonalLimitGroups,
  fetchPlayerFactorGroups,
  fetchRegionsFind,
  fetchSettleByPlayers,
  fetchSportsFind,
  fetchVoidReasonList
} from './fetch-functions';
import { IBetRequest } from './types';
import {
  DATE_FILTER_INTERVALS_UTC
} from '../components/search-and-filters-components/QueryStateFilterByPeriod';
import {  FILTER_INTERVALS } from '../constants';
import { BetType, QueryStateType } from '../types';

export const KEY_EVENT = 'event';
export const KEY_MARKETS = 'markets';
export const KEY_MARKET = 'market';
export const KEY_OPPONENTS = 'opponents';
export const KEY_PERIOD = 'period';
export const KEY_MARKET_TYPE = 'market-type';
export const KEY_SETTLE_BY_PLAYERS = 'settle-by-players';
export const KEY_VOID_REASON_LIST = 'void-reasons';
export const KEY_PLAYER_FACTOR_GROUP = 'player-factor-group';
export const KEY_BETS = 'bets';
export const KEY_SPORTS_FIND = 'sports-find';
export const KEY_REGIONS_FIND = 'regions-find';
export const KEY_LEAGUES_FIND = 'leagues-find';
export const KEY_LEAGUE_FIND_BY_ID = 'leagues-find-by-id';
export const KEY_ORIGIN_LIMIT_GROUP = 'origin-limit-group';
export const KEY_ORIGINS_MARGIN = 'KEY_ORIGINS_MARGIN';
export const KEY_EVENT_PATH_TREE_FIND = 'KEY_EVENT_PATH_TREE_FIND';
export const KEY_PERSONAL_LIMIT_GROUP = 'personal-limit-group';

export const useEvent = ({
  params,
  refetchInterval,
  enabled = true,
}:{
  params: {
    eventIds: string,
    originId: string,
  },
  refetchInterval?: number,
  enabled?: boolean,
}) => {
  const { eventIds, originId } = params;

  return useQuery({
    queryKey: [ KEY_EVENT, eventIds, originId ],
    queryFn:  () => fetchEvent( { eventIds, originId }),
    refetchInterval,
    enabled,
  });
};

export const useMarkets = ({
  params,
  refetchInterval,
  enabled = true,
}:{
  params: {
    eventId: string,
    originId?: string,
  },
  refetchInterval?: number,
  enabled?: boolean,
}) => {
  const { eventId, originId } = params;

  return useQuery({
    queryKey: [ KEY_MARKETS, eventId, originId ],
    queryFn:  () => fetchMarkets( { eventId, originId }),
    refetchInterval,
    enabled,
  });
};

export const useMarket = ({
  params,
  refetchInterval,
  enabled = true,
}:{
  params: {
    eventId: string,
    originId?: string,
  },
  refetchInterval?: number,
  enabled?: boolean,
}) => {
  const { eventId, originId } = params;

  return useQuery({
    queryKey: [ KEY_MARKET, eventId, originId ],
    queryFn:  () => fetchMarket( { eventId, originId }),
    refetchInterval,
    enabled,
  });
};

export const useOpponents = ({
  params,
  refetchInterval,
  enabled = true,
}:{
  params: {
    eventPathId: string,
    type: string,
  },
  refetchInterval?: number,
  enabled?: boolean,
}) => {
  const { eventPathId, type } = params;

  return useQuery({
    queryKey: [ KEY_OPPONENTS, eventPathId, type ],
    queryFn:  () => fetchOpponents( { eventPathId, type }),
    refetchInterval,
    enabled,
  });
};

export const useMarketType = ({
  params,
  refetchInterval,
  enabled = true,
}:{
  params: {
    sportEventPathId: string,
  },
  refetchInterval?: number,
  enabled?: boolean,
}) => {
  const { sportEventPathId } = params;

  return useQuery({
    queryKey: [ KEY_MARKET_TYPE, sportEventPathId ],
    queryFn:  () => fetchMarketType( { sportEventPathId }),
    refetchInterval,
    enabled,
  });
};

export const usePeriod = ({
  params,
  refetchInterval,
  enabled = true,
}:{
  params: {
    sportEventPathId: string,
  },
  refetchInterval?: number,
  enabled?: boolean,
}) => {
  const { sportEventPathId } = params;

  return useQuery({
    queryKey: [ KEY_PERIOD, sportEventPathId ],
    queryFn:  () => fetchPeriod( { sportEventPathId }),
    refetchInterval,
    enabled,
  });
};

export const useSettleByPlayers = ({
  params,
  refetchInterval,
  enabled = true,
}:{
  params: {
    queryState: QueryStateType,
    pageLimit: number,
    page: number,
  },
  refetchInterval?: number,
  enabled?: boolean,
}) => {
  const queryClient = useQueryClient();
  const { queryState, pageLimit, page } = params;
  const periodConfig =
    DATE_FILTER_INTERVALS_UTC.find(({ value }) => value === queryState.period) ||
    DATE_FILTER_INTERVALS_UTC.find(({ value }) => value === FILTER_INTERVALS.day.value);

  const paramsForRequest = {
    sortBy: queryState.sortBy || 'turnover',
    sortDir: queryState.sortDir || 'DESC',
    limit: page * pageLimit,
    originIds: queryState.originIds,
    lifetimeRes: queryState.showLifetime === 'true',
    sportIds: queryState.sportIds || undefined,
    startDate: periodConfig.value === FILTER_INTERVALS.custom.value
      ? queryState.startDate
      : periodConfig.getter(),
    endDate: periodConfig.value === FILTER_INTERVALS.custom.value
      ? queryState.endDate
      : periodConfig.dateNow()
  };

  return useQuery({
    queryKey: [ KEY_SETTLE_BY_PLAYERS, { ...queryState }, page * pageLimit ],
    queryFn: () => fetchSettleByPlayers(paramsForRequest),
    refetchInterval,
    enabled,
    initialData: () => queryClient.getQueryData(
      [ KEY_SETTLE_BY_PLAYERS, { ...queryState }, (page - 1) * pageLimit ]
    ) as any,
  });
};

export const useSportsFind = () => {
  return useQuery({
    queryKey: [ KEY_SPORTS_FIND ],
    queryFn: () => fetchSportsFind(),
  });
};

export const useRegionsFind = ({
  sportId,
  enabled,
}:{
  sportId: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: [ KEY_REGIONS_FIND, sportId ],
    queryFn: () => fetchRegionsFind(sportId),
    enabled,
  });
};

export const useLeaguesFind = ({
  regionId,
  enabled,
}:{
  regionId: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: [ KEY_LEAGUES_FIND, regionId ],
    queryFn: () => fetchLeagues(regionId),
    enabled,
  });
};

export const useVoidReasonList = () => {
  return useQuery({
    queryKey: [ KEY_VOID_REASON_LIST ],
    queryFn: () => fetchVoidReasonList(),
    staleTime: Infinity,
  });
};

export const usePlayerFactorGroup = ({
  refetchInterval,
}:{
  refetchInterval?: number,
}) => {
  return useQuery({
    queryKey: [ KEY_PLAYER_FACTOR_GROUP ],
    queryFn: () => fetchPlayerFactorGroups(),
    staleTime: Infinity,
    refetchInterval: refetchInterval,
  });
};

export const useBets = ({
  params,
  refetchInterval,
  staleTime = 10 * 1000,
  initialData,
  queryKey,
}:{
  params: IBetRequest,
  refetchInterval: number,
  staleTime?: number,
  initialData?: BetType[],
  queryKey?: any,
}) => {
  return useQuery({
    queryKey: queryKey || [ KEY_BETS, { ...params }, refetchInterval ],
    queryFn: () => fetchBets(params),
    refetchInterval: refetchInterval,
    initialData: initialData,
    staleTime,
  });
};

export const useCacheBets = ({
  params,
  refetchInterval,
  queryKey,
}:{
  params: IBetRequest,
  refetchInterval: number,
  queryKey?: any[],
}) => {
  const [ oldKey, setOldKey ] = useState(null);
  const [ bets, setBets ] = useState(null);
  const filteredQuery = queryKey.slice(0,3);

  const {
    data,
    isFetching,
    status,
    refetch,
  } = useBets({
    params: params,
    refetchInterval: refetchInterval,
    staleTime: 0,
    queryKey
  });

  const isFetchingCompose = useMemo(() => {
    return isFetching && !isEqual(oldKey, filteredQuery);
  },[ isFetching, oldKey, filteredQuery ]);

  useEffect(() => {
    if (
      status === 'success' && (
        !isEqual(data, bets) || (
          isEqual(data, bets) && isEqual(data, [])
        )
      )
    ) {
      setBets(data);
      setOldKey(filteredQuery);
    }
    // eslint-disable-next-line
  }, [ status, data ]);


  return {
    data: bets,
    isFetching: isFetchingCompose,
    simpleFetch: isFetching,
    refetch,
  };
};

export const useOriginLimitGroup = ({
  originId,
  refetchInterval,
}:{
  originId: string,
  refetchInterval?: number,
}) => {
  return useQuery({
    queryKey: [ KEY_ORIGIN_LIMIT_GROUP, originId ],
    queryFn: () => fetchLimitGroups(originId),
    refetchInterval: refetchInterval,
    staleTime: 0,
  });
};

export const useOriginsMargin = ({
  params,
  refetchInterval,
}:{
  params: {
    eventPathId: string,
    priceLineId: string
  }
  refetchInterval?: number,
}) => {
  return useQuery({
    queryKey: [ KEY_ORIGINS_MARGIN, params.eventPathId, params.priceLineId ],
    queryFn: () => fetchOriginMargins(params),
    refetchInterval: refetchInterval,
    retry: 3
  });
};

export const useEventPathTree = ({
  originId,
  onlyActiveOriginMargins,
}:{
  originId?: string,
  onlyActiveOriginMargins: boolean | null
}) => {
  return useQuery({
    queryKey: [ KEY_EVENT_PATH_TREE_FIND, originId, onlyActiveOriginMargins ],
    queryFn: () => fetchEventPathTree({
      originId,
      onlyActiveOriginMargins
    }),
    enabled: !!originId && onlyActiveOriginMargins !== null,
  });
};

export const useLeagueFindById = ({
  originId,
  eventPathIds,
}:{
  originId: string,
  eventPathIds: string
}) => {
  return useQuery({
    queryKey: [ KEY_LEAGUE_FIND_BY_ID, originId, eventPathIds ],
    queryFn: () => fetchLeagueById({
      originId,
      eventPathIds
    }),
    enabled: !!originId && !!eventPathIds,
  });
};

export const usePersonalLimitGroup = (
  {
    userId,
  }:{
    userId: string,
}) => {
  return useQuery({
    queryKey: [ KEY_PERSONAL_LIMIT_GROUP, userId ],
    queryFn: () => fetchPersonalLimitGroups(userId),
    staleTime: 0,
  });
};
