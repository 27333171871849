import React, { KeyboardEvent, MutableRefObject, SyntheticEvent } from 'react';

import { AxiosPromise } from 'axios';
import { FieldArray, Formik, ErrorMessage, FieldArrayRenderProps } from 'formik';
import moment from 'moment';
import { useOutletContext } from 'react-router-dom';
import {
  Modal,
  Button,
  Form,
  Header,
  Message,
  Label,
  Icon,
  Loader,
  Dropdown,
  Dimmer
} from 'semantic-ui-react';
import { $Keys } from 'utility-types';

import { getDefaultMarketDescription, validateMarketForm } from './helpers';
import { CashoutSettingsForm } from '../../../components/form-components/CashoutSettings';
import { FilterDatePicker } from '../../../components/form-components/FilterDatePicker';
import {
  PhoenixFieldDropdown,
  PhoenixField,
  PhoenixFieldRender,
  PhoenixFieldTextWithDefaultOrManuallyInput
} from '../../../components/form-components/FormComponents';
import { fetchOutcomes } from '../../../components/form-components/utils';
import {
  MARKET_STATUS_DROPDOWN_OPTIONS,
  OUTCOME_ORDINAL_POSITION_DROPDOWN_OPTIONS,
  DATE_FORMAT_3_DIGIT_MONTH_TIME,
  DEFAULT_OUTCOME,
  KEY_CODE_OF_ENTER,
  MARKET_TYPE_GROUP_PLAYER_PERFORMANCE,
  MARKET_TYPE_GROUP_OUTRIGHT,
  MARKET_TYPE_ID,
  PERIOD_ID,
  MARKET_STATUS_ENUM,
  CUTOFF_TIME,
  OUTCOMES,
  STATUS,
  DESCRIPTION,
  PLAYER_TYPE,
  TEAM_TYPE,
  BOOK_TYPES,
  BOOK_TYPES_ENUM, KEY_ENTER
} from '../../../constants';
import { useMarketType, useOpponents, usePeriod } from '../../../react-query/query';
import { EventOutletContextType, FieldTypesEnum, MarketType, TOS } from '../../../types';
import { calculateMargin } from '../../../utils';
import './MarketEditForm.scss';

type TMarketEditForm = {
  onClose: () => void;
  initialValues: MarketType | any;
  submit: (arg0: MarketType) => AxiosPromise | void;
  editing?: boolean;
  isMarketCreating?: boolean;
  marketManualMode?: boolean;
  marketBookType?: $Keys<typeof BOOK_TYPES>;
  fetchingValues?: boolean;
  parentDomId: string;
}

export const MarketEditForm = ({
  onClose,
  initialValues,
  submit,
  editing,
  isMarketCreating = false,
  marketManualMode = false,
  marketBookType,
  fetchingValues,
  parentDomId,
}: TMarketEditForm) => {
  const { event } = useOutletContext<EventOutletContextType>();

  const [ itemsTypeGroup, setItemsTypeGroup ] = React.useState(null);
  const [ configForm, setConfigForm ] = React.useState({
    eventId: event.id,
    marketTypeId: null,
    periodId: null,
    isLive: false,
    touchTime: false,
  });

  const {
    data: marketTypes,
    isFetching: marketTypeListStateFetching,
  } = useMarketType({
    params: { sportEventPathId: event.sportId },
  });

  const marketTypeOptions = marketTypes?.map(({ id, description, typeGroup }) => ({
    value: id,
    text: description,
    type: typeGroup,
  }));

  const {
    data: periods,
    isFetching: periodStateFetching,
  } = usePeriod({
    params: { sportEventPathId: event.sportId },
  });

  const periodOptions = periods?.map(({ description, id, inRunning }) => ({
    value: id,
    text: inRunning ? `${description} (Live)` : `${description} (Prematch)`,
    description,
  })) || [];

  const opponentOptions = React.useMemo(() => [
    { value: event.opponentHomeId, text: event.opponentHome },
    { value: event.opponentAwayId, text: event.opponentAway }
  ], [ event ]);

  const isEventNotClosed = initialValues.status !== MARKET_STATUS_ENUM.CLOSED;
  const isEventDisable = editing && !marketManualMode;
  const isDisableCashoutSettingsForm = !editing &&
    (initialValues.isBetBuilderMarket || (!isMarketCreating && isEventNotClosed));
  const componentId = `${parentDomId}${isMarketCreating ? 'add': 'edit'}-market-modal-`;

  React.useEffect(() => {
    if (editing && !marketTypeListStateFetching && !fetchingValues) {
      const typeGroup = marketTypeOptions
        ?.find((el: any) => el.value === initialValues?.marketTypeId)?.type || null;
      setItemsTypeGroup(typeGroup);
    }
    // eslint-disable-next-line
  }, [ marketTypeListStateFetching, fetchingValues, marketTypeOptions ]);

  async function onSubmit(values: any, { setSubmitting, setFieldError }) {
    setSubmitting(true);
    try {
      await submit(values);
      setSubmitting(false);
    } catch (error) {
      setFieldError('submit', error.message);
      setSubmitting(false);
    }
  }

  function cancelFormSubmitOnEnter (e: any) {
    if (e.keyCode === KEY_CODE_OF_ENTER) {
      e.preventDefault();
      return false;
    }
  }

  function fillOutcomes (data: any, cutoffTime: any, setFieldValue: any) {
    const newItemsTypeGroup = data.name === MARKET_TYPE_ID
      ? data.options.filter((el: any) => el.value === data.value)[0].type
      : itemsTypeGroup;

    if (data.name === MARKET_TYPE_ID) {
      setItemsTypeGroup(newItemsTypeGroup);
    }

    const isLive = data.name === PERIOD_ID
      ? data.options.filter((option: any) => option.value === data.value)[0].text?.includes('Live')
      : null;

    if (data.name === MARKET_TYPE_ID && configForm.periodId === null) {
      setConfigForm({ ...configForm, [data.name]: data.value });
      return;
    }

    if (data.name === PERIOD_ID && configForm.marketTypeId === null) {
      setConfigForm({
        ...configForm,
        [data.name]: data.value,
        isLive: isLive,
      });
      return;
    }

    const changeStartTime =
      (data.name === PERIOD_ID && !isLive && !cutoffTime && !configForm.touchTime) ||
      (data.name === MARKET_TYPE_ID && !configForm.isLive && !cutoffTime && !configForm.touchTime);

    if (changeStartTime) {
      setFieldValue(CUTOFF_TIME, event.startDate);
      setConfigForm({
        ...configForm,
        [data.name]: data.value,
        touchTime: true,
      });
    } else {
      setConfigForm({ ...configForm, [data.name]: data.value });
    }

    const isDefaultOutcome =
      newItemsTypeGroup === MARKET_TYPE_GROUP_PLAYER_PERFORMANCE ||
      newItemsTypeGroup === MARKET_TYPE_GROUP_OUTRIGHT;

    if (isDefaultOutcome) {
      setFieldValue(OUTCOMES, [ DEFAULT_OUTCOME, DEFAULT_OUTCOME ]);
      return;
    }

    fetchOutcomes({ setFieldValue, data, configForm });
  }

  return (
    <Formik
      validate={formValue => validateMarketForm(formValue, periods, itemsTypeGroup)}
      enableReinitialize={editing}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnMount
      validateOnBlur
    >
      {({
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        values,
        errors,
        isValid,
        handleSubmit,
        dirty,
      }) => {
        return (
          <>
            {fetchingValues && (
              <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
            )}
            <Modal.Header>
              {isMarketCreating ? 'Add' : 'Edit'} market: {values.description || ''}
              <Header as="h4" color="grey" className="market-edit-form__modal-header">
                {moment(event.startDate).format(DATE_FORMAT_3_DIGIT_MONTH_TIME)}
                {!isMarketCreating && (
                  <Label color="grey">
                    {marketBookType === BOOK_TYPES_ENUM.BOOKTYPE_LIVE ? 'Live' : 'Prematch'}
                  </Label>
                )}
                <br />
                {event?.description ?? '-'}
              </Header>
            </Modal.Header>
            <Modal.Content>
              <Form loading={isSubmitting} onKeyDown={cancelFormSubmitOnEnter}>
                <Form.Group>
                  <PhoenixField
                    id={componentId + 'hide-mode-checkbox'}
                    type={FieldTypesEnum.CHECKBOX}
                    label="Hide mode"
                    name="hideMode"
                    disabled={initialValues.isBetBuilderMarket}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <PhoenixFieldDropdown
                    id={componentId + 'market-type-dropdown'}
                    required
                    label="Market Type"
                    name={MARKET_TYPE_ID}
                    loading={marketTypeListStateFetching}
                    options={marketTypeOptions}
                    disabled={editing}
                    cutoffTime={values.cutoffTime}
                    fillOutcomes={fillOutcomes}
                  />
                  <PhoenixFieldDropdown
                    id={componentId + 'period-dropdown'}
                    required
                    label="Period"
                    name={PERIOD_ID}
                    loading={periodStateFetching}
                    options={periodOptions}
                    disabled={editing}
                    cutoffTime={values.cutoffTime}
                    fillOutcomes={fillOutcomes}
                  />
                  <PhoenixFieldDropdown
                    id={componentId + 'status-dropdown'}
                    required
                    label="Status"
                    name={STATUS}
                    options={MARKET_STATUS_DROPDOWN_OPTIONS}
                  />
                  <Form.Field disabled={editing && event.isLive}>
                    <label>Cutoff date</label>
                    <FilterDatePicker
                      id={componentId + 'cutoff-time-field'}
                      name={CUTOFF_TIME}
                      selected={values.cutoffTime}
                      onChange={data => setFieldValue(CUTOFF_TIME, data)}
                      showTimeSelect
                      onBlur={() => setFieldTouched(CUTOFF_TIME)}
                    />
                    <ErrorMessage name={CUTOFF_TIME} />
                  </Form.Field>
                </Form.Group>

                <Form.Group widths={12} className="market-edit-form__form-description-group">
                  {editing ? (
                    <PhoenixField
                      id={componentId + 'description-field'}
                      label="Description"
                      name={DESCRIPTION}
                      width={12}
                    />
                  ) : (
                    <PhoenixFieldRender label="Description" name={DESCRIPTION} width={12} required>
                      {({ form: { setFieldValue }, field }) => (
                        <PhoenixFieldTextWithDefaultOrManuallyInput
                          id={componentId + 'description-field'}
                          dynamicDefaultValue={getDefaultMarketDescription({
                            ...values,
                            marketTypeOptions,
                            periodOptions,
                            opponentOptions,
                          })}
                          setFieldValue={setFieldValue}
                          {...field}
                        />
                      )}
                    </PhoenixFieldRender>
                  )}

                  {/*<Form.Field>*/}
                  {/*  <Header as="h5">Cashout settings</Header>*/}
                  {/*  <CashoutSettingsForm*/}
                  {/*    id={componentId}*/}
                  {/*    enabled={initialValues.cashoutEnabled}*/}
                  {/*    showInherit*/}
                  {/*    cashoutInheritedName="cashoutInherited"*/}
                  {/*    cashoutEnabledName="cashoutEnabled"*/}
                  {/*    disableAll={isDisableCashoutSettingsForm}*/}
                  {/*  />*/}
                  {/*</Form.Field>*/}
                </Form.Group>

                <OutcomesSegment
                  values={values}
                  isSubmitting={isSubmitting}
                  isEventDisable={isEventDisable}
                  itemsTypeGroup={itemsTypeGroup}
                  setFieldValue={setFieldValue}
                  componentId={componentId}
                />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              {errors.submit && <Message negative>{String(errors.submit)}</Message>}
              <Button
                id={componentId + 'cancel-button'}
                content="Cancel"
                type="button"
                color="red"
                onClick={onClose}
              />
              <Button
                id={componentId + 'submit-button'}
                content="Save"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting || !isValid || !dirty}
                // @ts-ignore
                onClick={handleSubmit}
                color="green"
              />
            </Modal.Actions>
          </>
        );
      }}
    </Formik>
  );
};

type TOutcomeSegment = {
  values: any,
  isSubmitting: boolean,
  isEventDisable: boolean,
  itemsTypeGroup: string,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  componentId?: string;
}

const OutcomesSegment = ({
  values,
  isSubmitting,
  isEventDisable,
  itemsTypeGroup,
  setFieldValue,
  componentId,
}: TOutcomeSegment) => {
  const { event } = useOutletContext<EventOutletContextType>();

  const [ fetch, setFetch ] = React.useState({ teams: false, players: false });

  const {
    data: teamsList,
    isFetching: teamsFetch,
  } = useOpponents({
    params: { eventPathId: event.leagueId, type: TEAM_TYPE },
    enabled: fetch.teams,
  });
  const {
    data: playersList,
    isFetching: playersFetch,
  } = useOpponents({
    params: { eventPathId: event.leagueId, type: PLAYER_TYPE },
    enabled: fetch.players,
  });

  const isGetButtonsDisable = !values[MARKET_TYPE_ID] || !values[PERIOD_ID];
  const margin = calculateMargin({ prices: values.outcomes.map((outcome: any) => outcome.price) });
  const formatMargin = typeof margin === 'string' && margin === ''
    ? margin
    : `${Math.round(Number(margin) * 10) / 10 + 100}%`;

  const arrOfPricesRefs = React.useRef([]);
  arrOfPricesRefs.current = [];

  const arrOfDescriptionsRefs = React.useRef([]);
  arrOfDescriptionsRefs.current = [];

  function addToRefs(el: HTMLElement, arr: MutableRefObject<HTMLElement[]>) {
    if (el && !arr?.current?.includes(el)) {
      arr.current.push(el);
    }
  }

  async function addOutcome(
    arrayHelpers: FieldArrayRenderProps,
    values: TOS
  ) {
    await arrayHelpers.push({ ...DEFAULT_OUTCOME });
    await arrOfDescriptionsRefs.current[
      values.outcomes.length
      ].firstElementChild.lastElementChild.firstElementChild.focus();
  }

  async function descriptionOnKeyDown(
    e: KeyboardEvent<HTMLInputElement>,
    index: number,
    arrayHelpers: FieldArrayRenderProps
  ) {
    if (e.key === KEY_ENTER && index + 1 !== arrOfDescriptionsRefs.current.length) {
      await arrOfDescriptionsRefs.current[
      index + 1
        ].firstElementChild.lastElementChild.firstElementChild.focus();
    }
    if (e.key === KEY_ENTER && index + 1 === arrOfDescriptionsRefs.current.length) {
      await arrayHelpers.push({
        ...DEFAULT_OUTCOME,
      });
    }
  }

  function priceOnKeyDown(e: KeyboardEvent<HTMLInputElement>, index: number) {
    if (e.key === KEY_ENTER && index + 1 !== arrOfPricesRefs.current.length) {
      arrOfPricesRefs.current[index + 1].firstElementChild.lastElementChild.firstElementChild.focus();
    }
  }

  function deleteOutcome (arrayHelpers: FieldArrayRenderProps, index: number) {
    if (!isEventDisable) arrayHelpers.remove(index);
  }

  function getOpponents (type: string) {
    if (type === TEAM_TYPE) {
      setFetch(p => ({
        ...p, teams: true
      }));
    } else {
      setFetch(p => ({
        ...p, players: true
      }));
    }
  }

  function addOutcomeFromDropdown (_e: SyntheticEvent, data: TOS) {
    const isNoOutcomes = !values?.outcomes?.length;
    const isEmptyOutcomes = values?.outcomes?.every((outcome: any) => outcome.description === '');

    if (isNoOutcomes || isEmptyOutcomes) {
      setFieldValue(OUTCOMES, []);
      setFieldValue(OUTCOMES, [{ ...DEFAULT_OUTCOME, description: data?.value || '' }]);
      return;
    }

    const newOutcomes = [ ...values.outcomes, { ...DEFAULT_OUTCOME, description: data?.value || '' }];
    setFieldValue(OUTCOMES, newOutcomes);
  }

  return (
    <FieldArray
      name="outcomes"
      render={arrayHelpers => (
        <>
          <div className="market-edit-form__outcomes-header">
            <Header floated="left" as="h3">
              Outcomes
            </Header>
            <div>
              {!teamsList?.length ? (
                <Button
                  id={componentId + 'get-teams-button'}
                  type="button"
                  content={Array.isArray(teamsList) ? 'No teams' : 'Get teams'}
                  icon={Array.isArray(teamsList) ? null : 'arrow down'}
                  onClick={() => getOpponents(TEAM_TYPE)}
                  loading={teamsFetch}
                  disabled={isGetButtonsDisable || Array.isArray(teamsList) || isEventDisable}
                />
              ) : (
                <Dropdown
                  id={componentId + 'teams-dropdown'}
                  search
                  selection
                  options={teamsList}
                  onChange={addOutcomeFromDropdown}
                  closeOnChange={false}
                  text="Select teams"
                />
              )}
              {!playersList?.length ? (
                <Button
                  id={componentId + 'get-players-button'}
                  type="button"
                  content={Array.isArray(playersList) ? 'No players' : 'Get players'}
                  icon={Array.isArray(playersList) ? null : 'arrow down'}
                  onClick={() => getOpponents(PLAYER_TYPE)}
                  loading={playersFetch}
                  disabled={isGetButtonsDisable || Array.isArray(playersList) || isEventDisable}
                />
              ) : (
                <Dropdown
                  id={componentId + 'players-dropdown'}
                  search
                  selection
                  options={playersList}
                  onChange={addOutcomeFromDropdown}
                  closeOnChange={false}
                  text="Select players"
                />
              )}

              <Button
                id={componentId + 'add-outcome-button'}
                type="button"
                primary
                disabled={isSubmitting || isEventDisable}
                icon="add"
                content="Add outcome"
                onClick={() => addOutcome(arrayHelpers, values)}
              />
            </div>
          </div>

          {values.outcomes.map((_v: any, index: number) => (
            <Form.Group
              key={index}
              className="market-edit-form__form-outcome-group"
            >
              <button
                className="market-edit-form__btn-delete-wrapper"
                id={componentId + 'delete-' + index + '-outcome-button'}
                onClick={() => deleteOutcome(arrayHelpers, index)}
                style={isEventDisable ? { cursor: 'default' } : null}
              >
                <Icon
                  name="delete"
                  size="large"
                  disabled={isEventDisable}
                />
              </button>

              <div
                className="market-edit-form__outcome-description-wrapper"
                ref={el => addToRefs(el, arrOfDescriptionsRefs)}
              >
                <PhoenixField
                  id={componentId + 'description-' + index + '-field'}
                  label={index === 0 && 'Description'}
                  placeholder="Description"
                  required
                  name={`outcomes.${index}.description`}
                  disabled={isEventDisable}
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                    descriptionOnKeyDown(e, index, arrayHelpers)
                  }
                />
              </div>

              <div
                className="market-edit-form__outcome-price-wrapper"
                ref={el => addToRefs(el, arrOfPricesRefs)}
              >
                <PhoenixField
                  id={componentId + 'price-' + index + '-field'}
                  label={index === 0 && 'Price'}
                  placeholder="Price"
                  name={`outcomes.${index}.price`}
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => priceOnKeyDown(e, index)}
                />
              </div>

              <div className="market-edit-form__outcome-position-wrapper">
                {itemsTypeGroup !== MARKET_TYPE_GROUP_OUTRIGHT && (
                  <PhoenixFieldDropdown
                    id={componentId + 'position-' + index + '-dropdown'}
                    label={index === 0 && 'Position'}
                    required
                    placeholder="Position"
                    name={`outcomes.${index}.ordinalPosition`}
                    options={OUTCOME_ORDINAL_POSITION_DROPDOWN_OPTIONS}
                    disabled={isEventDisable}
                  />
                )}
              </div>
            </Form.Group>
          ))}

          <Form.Group>
            <div className="market-edit-form__empty-first-block"></div>
            <div className="market-edit-form__margin-total-block">{formatMargin}</div>
            <div className="market-edit-form__empty-second-block"></div>
          </Form.Group>
        </>
      )}
    />
  );
};
