import axios, { AxiosResponse } from 'axios';
import qs from 'qs';

import {
  IEventPathTreeResponse,
  ILeagueFindByIdResponse,
  ILeaguesFindResponse,
  IRegionsFindResponse,
  ISportsFindResponse
} from './types';
import {
  API_ACCOUNT,
  API_BETS,
  API_EVENT_PATH_TREE_FIND,
  API_EVENTS_FIND_BY_ID,
  API_LEAGUES_FIND,
  API_LEAGUES_FIND_BY_ID,
  API_MARKET_FIND,
  API_MARKET_TYPE_FIND,
  API_OPPONENT_FIND,
  API_ORIGIN,
  API_ORIGIN_MARGINS,
  API_PERIOD_FIND,
  API_PLAYER_FACTOR_GROUPS,
  API_REGIONS_FIND,
  API_SETTLE_BY_PLAYER,
  API_SINGLES_BY_EVENT,
  API_SPORTS_FIND,
  API_VOID_REASON_LIST
} from '../constants';
import {
  LeagueLimitGroup,
  BetType,
  PlayerFactorGroup,
  TOS,
  VoidReasonsItem,
  IOriginMargin,
  TPersonalLimitGroup
} from '../types';

export const fetchEvent = async ({ eventIds, originId }) => {
  const config = {
    url: API_EVENTS_FIND_BY_ID,
    params: { eventIds, originId },
  };
  const response = await axios(config);
  return response.data.result[0];
};

export const fetchMarkets = async ({ eventId, originId }) => {
  const config = {
    url: API_SINGLES_BY_EVENT,
    params: { eventId, originId },
  };
  const response = await axios(config);
  return response?.data?.result?.markets || [];
};

export const fetchMarket = async ({ eventId, originId }) => {
  const config = {
    url: API_MARKET_FIND,
    params: { eventId, originId },
  };
  const response = await axios(config);
  return response?.data?.result;
};

export const fetchOpponents = async ({ eventPathId, type }) => {
  const config = {
    url: API_OPPONENT_FIND,
    params: {
      eventPathId,
      type,
      limit: 99999,
    },
  };
  const response = await axios(config);
  const result = response?.data?.result;

  if (!result?.length) {
    return [];
  }

  return result.map((item: TOS) => ({
    key: item.id,
    value: item.description,
    text: item.description
  }));
};

export const fetchMarketType = async ({ sportEventPathId }) => {
  const config = {
    url: API_MARKET_TYPE_FIND,
    params: {
      limit: 0,
      sportEventPathId: sportEventPathId,
    }
  };
  const response = await axios(config);
  const result = response?.data?.result;

  if (!result || !Array.isArray(result)) {
    return [];
  }

  return result;
};

export const fetchPeriod = async ({ sportEventPathId }) => {
  const config = {
    url: API_PERIOD_FIND,
    params: {
      limit: 0,
      sportEventPathId: sportEventPathId,
    }
  };
  const response = await axios(config);
  const result = response?.data?.result;

  if (!result || !Array.isArray(result)) {
    return [];
  }

  return result;
};
export const fetchSettleByPlayers = async (params: object) => {
  const config = {
    url: API_SETTLE_BY_PLAYER,
    params: {
      ...params,
    },
    paramsSerializer: v => qs.stringify(v, { arrayFormat: 'repeat' })
  };
  const response = await axios(config);
  return response?.data?.result;
};

export const fetchSportsFind = async () => {
  const response = await axios.get<ISportsFindResponse>(
    API_SPORTS_FIND, {
    params: {
      limit: 99999,
    },
  });

  return response?.data?.result;
};

export const fetchRegionsFind = async (sportIds: string) => {
  const response = await axios.get<IRegionsFindResponse>(
    API_REGIONS_FIND,{
    params: {
      sportIds,
      limit: 99999,
    }
  });

  return response?.data?.result;
};

export const fetchLeagues = async (regionIds: string) => {
  const response = await axios.get<ILeaguesFindResponse>(
    API_LEAGUES_FIND,{
    params: {
      regionIds,
      limit: 99999,
    }
  });

  return response?.data?.result;
};


export const fetchVoidReasonList = async () => {
  const config = {
    url: API_VOID_REASON_LIST,
  };
  const response: AxiosResponse<{result: VoidReasonsItem[]}> = await axios(config);
  return response?.data?.result || [];
};

export const fetchPlayerFactorGroups = async () => {
  const config = {
    url: API_PLAYER_FACTOR_GROUPS,
  };
  const response: AxiosResponse<{result: PlayerFactorGroup[]}> = await axios(config);
  return response?.data?.result;
};

export const fetchBets = async (params: object) => {
  const config = {
    url: API_BETS,
    params: { ...params },
    paramsSerializer: v => qs.stringify(v, { arrayFormat: 'repeat' })
  };
  const response: AxiosResponse<{result: BetType[]}> = await axios(config);

  return response?.data?.result || [];
};

export const fetchLimitGroups = async (origin: string) => {
  const config = {
    url: `${API_ORIGIN}/${origin}/limitGroups`,
  };

  const response: AxiosResponse<{result: LeagueLimitGroup[]}> = await axios(config);
  return response?.data?.result;
};

export const fetchOriginMargins = async (params: {eventPathId: string, priceLineId: string}) => {
  const config = {
    url: API_ORIGIN_MARGINS,
    params
  };

  const response: AxiosResponse<{result: IOriginMargin[]}> = await axios(config);
  return response?.data?.result;
};

export const fetchEventPathTree = async (params: {
  originId: string,
  onlyActiveOriginMargins: boolean
}) => {
  const response = await axios.get<IEventPathTreeResponse>(
    API_EVENT_PATH_TREE_FIND, {
      params
  });

  return response?.data?.result;
};

export const fetchLeagueById = async (params: {
  originId: string,
  eventPathIds: string
}) => {
  const response = await axios.get<ILeagueFindByIdResponse>(
    API_LEAGUES_FIND_BY_ID, {
      params
  });

  return response?.data?.result?.[0];
};

export const fetchPersonalLimitGroups = async (userId: string) => {
  const config = {
    url: `${API_ACCOUNT}/${userId}/limitGroups`,
  };
  const response: AxiosResponse<{result: TPersonalLimitGroup}> = await axios(config);
  return response?.data?.result;
};
