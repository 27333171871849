import { Button, Checkbox } from 'semantic-ui-react';

export const UpdateButtons = (
  {
    submit,
    disabled,
    enabledLimits,
    changeEnabledStatus
  }:{
    submit: () => void
    disabled: boolean;
    enabledLimits: boolean
    changeEnabledStatus: () => void;
  }
) => {
  return (
    <div>
      <Button
        id="limits-page-save-btn"
        positive
        disabled={disabled}
        onClick={submit}
        content="Save"
        style={{ marginRight: '20px' }}
      />
      <Checkbox
        id={'enabled-personal-limits-checkbox'}
        checked={enabledLimits}
        label="Limit overrides"
        onChange={changeEnabledStatus}
        toggle
      />;
    </div>
  )
}
