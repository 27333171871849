import { ReactNode, useCallback, useMemo, useState } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Layout } from './components/UI/Layout';
import {
  ANOTHER_PATH,
  BETS_PATH,
  EMPTY_PATH,
  EVENT_BET_RESTRICTIONS_PATH,
  EVENT_BETS_PATH,
  EVENT_CREATE_PATH,
  EVENT_EDIT_WITH_ID_PATH,
  EVENT_IRM_PATH,
  EVENT_MARKETS_PATH,
  EVENTS_PATH,
  EVENT_TREE_LEAGUE_PATH,
  EVENT_TREE_NEW_LEAGUE_PATH,
  EVENT_TREE_NEW_REGION_PATH,
  EVENT_TREE_PATH,
  EVENT_TREE_REGION_PATH,
  EVENT_TREE_SPORT_PATH,
  HOME_PATH,
  LIMIT_GROUPS_PATH,
  MARKET_TYPE_OVERRIDE_PATH,
  MULTIPLES_PATH,
  NEW_LEAGUES_PATH,
  ORIGIN_SETTINGS_PATH,
  SELECTION_PATH,
  SETTLED_PATH,
  SINGLES_PATH,
  TRADER_PERM_ALL,
  TRADER_PERM_EVENT_EDITOR,
  TRADER_PERM_UTILITIES,
  USER_WITH_ID_PATH,
  NEW_BETS, LINK_PERSONAL_LIMITS
} from './constants';
import { useGlobalStateContext } from './hooks-and-global-states/global-context';
import { Bets } from './pages/Bets/Bets';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { EventCreate } from './pages/EventCreate/EventCreate';
import { TabBetRestrictions } from './pages/EventEdit/BetRestriction/TabBetRestrictions';
import { BetsByEvent } from './pages/EventEdit/Bets/BetsByEvent';
import { EventInfo } from './pages/EventEdit/Description/EventInfo';
import { EventEdit } from './pages/EventEdit/EventEdit';
import { IRM } from './pages/EventEdit/IRM/IRM';
import { Markets } from './pages/EventEdit/Markets/Markets';
import { EditLeague } from './pages/EventPaths/EditLeague';
import { EditRegion } from './pages/EventPaths/EditRegion';
import { EditSport } from './pages/EventPaths/EditSport';
import { LeagueFormCreate, RegionFormCreate } from './pages/EventPaths/EventPathForms';
import { EventPaths } from './pages/EventPaths/EventPaths';
import { EventListContainerRedirect } from './pages/Events/EventListContainer';
import { LimitGroups } from './pages/LimitGroups/LimitGroups';
import { MarketTypeOverride } from './pages/MarketTypeOverride/MarketTypeOverride';
import { NewBets } from './pages/NewBets/ui/NewBets';
import { NewLeaguesReport } from './pages/NewLeagues/NewLeaguesReport';
import { OriginSettings } from './pages/OriginSettings/OriginSettings';
import { LargeSingles, LargeMultiples } from './pages/Risk/LargeBets';
import { RiskManager } from './pages/Risk/RiskManager';
import { SettledByPlayers } from './pages/settled-by-players';
import 'react-datepicker/dist/react-datepicker.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { BetsByUser } from './pages/UserPage/BetsByUser';
import { PersonalLimitsTable } from './pages/UserPage/PersonalLimitsTable';
import { UserPage } from './pages/UserPage/UserPage';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export const App = ({ username, logout }: { username: string; logout: () => void }) => {
  const { permissions } = useGlobalStateContext();

  const [ sidebarContent, setSidebarContent ] = useState(null);

  const openSidebar = useCallback((content: ReactNode) => setSidebarContent(content), []);

  const closeSidebar = useCallback(() => setSidebarContent(null), []);

  const enableUtilities = useMemo(() => (
    !permissions?.denyPermissions?.includes(TRADER_PERM_UTILITIES) &&
    [ TRADER_PERM_UTILITIES, TRADER_PERM_ALL ].some(permission =>
      permissions?.allowPermissions?.includes(permission)
    )
  ), [ permissions ]);

  const enableEditing = useMemo(() => (
    !permissions?.denyPermissions?.includes(TRADER_PERM_EVENT_EDITOR) &&
    [ TRADER_PERM_EVENT_EDITOR, TRADER_PERM_ALL ].some(permission =>
      permissions?.allowPermissions?.includes(permission)
    )
  ), [ permissions?.allowPermissions, permissions?.denyPermissions ]);

  const layoutProps = useMemo(() => ({
    username,
    logout,
    enableUtilities,
    closeSidebar,
    sidebarContent,
  }),[ closeSidebar, enableUtilities, logout, sidebarContent, username ]);

  return (
    <QueryClientProvider client={queryClient}>
      <Layout {...layoutProps}>
        <Routes>
          <Route path={HOME_PATH} element={<Dashboard openSidebar={openSidebar} />}/>
          <Route path={EVENTS_PATH} element={<EventListContainerRedirect />}/>
          <Route path={EVENT_CREATE_PATH} element={<EventCreate />}/>
          <Route path={EVENT_EDIT_WITH_ID_PATH} element={<EventEdit />}>
            <Route path={EMPTY_PATH} element={<EventInfo />} />
            <Route path={EVENT_MARKETS_PATH} element={<Markets openSidebar={openSidebar} />}/>
            {enableEditing && (
              <>
                <Route path={EVENT_BET_RESTRICTIONS_PATH} element={<TabBetRestrictions/>}/>
                <Route path={EVENT_IRM_PATH} element={<IRM openSidebar={openSidebar}/>}/>
              </>
            )}
            <Route path={EVENT_BETS_PATH} element={<BetsByEvent />} />
          </Route>
          <Route path={SELECTION_PATH} element={<RiskManager openSidebar={openSidebar} />} />
          <Route path={SINGLES_PATH} element={<LargeSingles />} />
          <Route path={MULTIPLES_PATH} element={<LargeMultiples />} />
          <Route path={BETS_PATH} element={<Bets />} />
          <Route path={SETTLED_PATH} element={<SettledByPlayers />} />
          <Route path={USER_WITH_ID_PATH} element={<UserPage />}>
            <Route path={EMPTY_PATH} element={<BetsByUser />} />
            <Route path={LINK_PERSONAL_LIMITS} element={<PersonalLimitsTable/>} />
          </Route>
          {enableUtilities && (
            <>
              <Route path={LIMIT_GROUPS_PATH} element={<LimitGroups />} />
              <Route path={ORIGIN_SETTINGS_PATH} element={<OriginSettings />} />
              <Route path={NEW_LEAGUES_PATH} element={<NewLeaguesReport />} />
              <Route path={MARKET_TYPE_OVERRIDE_PATH} element={<MarketTypeOverride />} />
              <Route path={EVENT_TREE_PATH} element={<EventPaths />}>
                <Route path={EVENT_TREE_SPORT_PATH} element={<EditSport />} />
                <Route path={EVENT_TREE_NEW_REGION_PATH} element={<RegionFormCreate />} />
                <Route path={EVENT_TREE_REGION_PATH} element={<EditRegion />} />
                <Route path={EVENT_TREE_NEW_LEAGUE_PATH} element={<LeagueFormCreate />} />
                <Route path={EVENT_TREE_LEAGUE_PATH} element={<EditLeague />} />
              </Route>
            </>
          )}
          <Route path={ANOTHER_PATH} element={<Navigate to={HOME_PATH} />} />
          <Route path={NEW_BETS} element={<NewBets />} />
        </Routes>
        <ToastContainer />
      </Layout>
    </QueryClientProvider>
  );
};
