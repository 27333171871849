import { useEffect, useRef } from 'react';

import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Button, Icon, Grid, List } from 'semantic-ui-react';

import { EventFilters } from './EventFilters';
import { EventTable } from './EventTable';
import { TRADER_PERM_EVENT_EDITOR, TRADER_PERM_ALL } from '../../constants';
import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';
import { useQueryState } from '../../hooks-and-global-states/history-hooks';

const EventListContainer = () => {
  const navigate = useNavigate();
  const { permissions } = useGlobalStateContext();

  const enableAddEvent =
    !permissions?.denyPermissions?.includes(TRADER_PERM_EVENT_EDITOR) &&
    [ TRADER_PERM_EVENT_EDITOR, TRADER_PERM_ALL ].some(permission =>
      permissions?.allowPermissions?.includes(permission)
    );

  return (
    <Grid columns="equal" className="content-container">
      <Grid.Column width="3">
        <EventFilters />
      </Grid.Column>

      <Grid.Column columns={13}>
        {enableAddEvent && (
          <List floated="right">
            <List.Item>
              <Button
                className="ui positive button"
                id="events-add-event"
                onClick={() => navigate('/event-create')}
                type="button"
              >
                <Icon name="add" />
                Add event
              </Button>
            </List.Item>
          </List>
        )}

        <EventTable />
      </Grid.Column>
    </Grid>
  );
};

export const EventListContainerRedirect = () => {
  const { queryState, setQueryState } = useQueryState();
  const firstRedirect = useRef(true);
  useEffect(() => {
    if (firstRedirect.current) {
      firstRedirect.current = false;
      if (!queryState.startDateFrom) {
        const now = moment().subtract(2, 'hours').startOf('minute').format();
        setQueryState('startDateFrom', now);
      }
    }
  }, [ queryState.startDateFrom, setQueryState ]);

  return <EventListContainer />;
};
