import React, { useMemo } from 'react';

import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { Card, Menu } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import { PortalUserLink } from './PortalUserLink';
import { UserPerformance } from './UserPerfomance';
import { TableCellPlayerInfo } from '../../components/table-components/TableCellPlayerInfo';
import {
  API_PLAYER_BY_ID,
  LINK_PERSONAL_LIMITS
} from '../../constants';
import './UserPage.scss';

export const UserPage = () => {
  const { userId } = useParams();
  const { pathname } = useLocation();

  const personalLimitsIsActive = pathname?.includes(LINK_PERSONAL_LIMITS);
  const userBetsIsActive = !personalLimitsIsActive

  const { data } = useAxiosRequest<any>(
    React.useMemo(() => ({
      url: API_PLAYER_BY_ID,
      params: {
        id: userId,
      },
    }), [ userId ])
  );

  const outletContext = useMemo(() => {
    return {
      userId: userId,
    };
  }, [ userId ]);

  if (!data) {
    return null;
  }

  const {
    result: { currency, firstName, lastName, origin, username, id, performance, userFactorGroup, overrideLimits },
  } = data;

  return (
    <div className="user-page-container">
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <TableCellPlayerInfo
              parentDomId="user-page--"
              noLink
              firstName={firstName}
              lastName={lastName}
              id={id}
              origin={origin.id}
              username={username}
              userFactorGroupId={userFactorGroup.id}
              floatedLabels
              overrideLimits={overrideLimits}
            />
            <PortalUserLink icon id={id}>
              Go to Portal
            </PortalUserLink>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Card.Description>
            {performance && (
              <UserPerformance
                totalBets={performance.totalBets}
                totalTurnover={performance.totalTurnover}
                totalPayout={performance.totalPayout}
                totalProfit={performance.totalProfit}
                totalTurnoverUSD={performance.totalTurnoverUSD}
                totalPayoutUSD={performance.totalPayoutUSD}
                totalProfitUSD={performance.totalProfitUSD}
                totalMargin={performance.totalMargin}
                currency={currency}
              />
            )}
          </Card.Description>
        </Card.Content>
      </Card>
      <Menu tabular>
        <Menu.Item
          id="user-bets-tab"
          as={Link}
          name="Main"
          to=""
          active={userBetsIsActive}
        />
        <Menu.Item
          id="personal-limits-tab"
          as={Link}
          name="Personal Limits"
          to={LINK_PERSONAL_LIMITS}
          active={personalLimitsIsActive}
        />
      </Menu>
      <Outlet context={outletContext} />
    </div>
  );
};
