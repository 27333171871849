import { useMemo } from 'react';

import { useOutletContext } from 'react-router-dom';

import {
  INTERVALS_TYPE_1
} from '../../components/search-and-filters-components/QueryStateFilterByPeriod';
import { FILTER_INTERVALS } from '../../constants';
import { BetsColumnLabelEnum, BetsTableFiltersEnum, UserOutletContextType } from '../../types';
import { BetsHistoryWithLiveMode } from '../Bets/BetsHistoryWithLiveMode';


const FILTERS = [
  BetsTableFiltersEnum.betId,
  BetsTableFiltersEnum.eventId,
  BetsTableFiltersEnum.betType,
  BetsTableFiltersEnum.betStatuses,
  BetsTableFiltersEnum.betRejectReasons,
  BetsTableFiltersEnum.bookType,
  BetsTableFiltersEnum.stake,
  BetsTableFiltersEnum.period,
  BetsTableFiltersEnum.sports
];

const COLUMNS = [
  BetsColumnLabelEnum.Date,
  BetsColumnLabelEnum.Outcomes,
  BetsColumnLabelEnum.Type,
  BetsColumnLabelEnum.Stake,
  BetsColumnLabelEnum.Price,
  BetsColumnLabelEnum.Winning,
  BetsColumnLabelEnum.Status,
  BetsColumnLabelEnum.Actions
];

export const BetsByUser = () => {
  const { userId } = useOutletContext<UserOutletContextType>();
  const params = useMemo(() => ({
    limit: 10,
    userIds: [ userId ],
  }),[ userId ]);

  return (
    <BetsHistoryWithLiveMode
      columns={COLUMNS}
      filters={FILTERS}
      params={params}
      showCopyButtons={{
        betId: true
      }}
      parentDomId="user-page--"
      defaultPeriod={FILTER_INTERVALS.all.value}
      intervalsOptionsType={INTERVALS_TYPE_1}
    />
  );
};
