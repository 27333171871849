import { CancelTokenSource } from 'axios';
import { $Keys } from 'utility-types';

import {
  MARKET_STATUS_ENUM,
  BOOK_TYPES,
  UNKNOWN_PLAYER_FACTOR_GROUP,
  BetStatusesEnum,
  BetsSortByEnum,
  OutcomeResultsEnum
} from './constants';
import { BetsColumnEnum } from './pages/NewBets/model/constants';

export type Polling = {
  intervalId: ReturnType<typeof setInterval>;
  cancelToken: CancelTokenSource;
};
export type PeriodType = {
  id: string;
  description: string;
  inRunning: boolean;
  length: number;
  parentId: string;
  sequence: number;
};
export type OutcomeType = {
  id: string;
  description: string;
  price?: number;
  opponentId: string;
  hideModeFeed: boolean;
  hideModeTrader: boolean;
  result: ResultType;
  voidReason: VoidReason;
  priceId?: string;
  voidReasonId?: string;
  version: string;
  ordinalPosition?: string;
  index: number;
  manualMode: boolean;
  spread: number;
};

export type ResultType = 'OUTCOME_RESULT_UNKNOWN'
  | 'WIN'
  | 'LOSE'
  | 'NO_RESULT'
  | 'VOID'

  | 'PLACE_2'
  | 'PLACE_3'
  | 'PLACE_4'
  | 'PLACE_5'
  | 'PLACE_6'
  | 'PLACE_7'
  | 'PLACE_8'
  | 'PLACE_9'
  | 'PLACE_10'
  | 'PLACE_11'
  | 'PLACE_12'
  | 'PLACE_13'
  | 'PLACE_14'
  | 'PLACE_15'
  | 'PLACE_16'
  | 'PLACE_17'
  | 'PLACE_18'
  | 'PLACE_19'
  | 'PLACE_20'
  | 'PLACE_21'
  | 'PLACE_22'
  | 'PLACE_23'
  | 'PLACE_24'
  | 'PLACE_25'
  | 'PLACE_26'
  | 'PLACE_27'
  | 'PLACE_28'
  | 'PLACE_29'
  | 'PLACE_30'
  | 'PLACE_31'
  | 'PLACE_32'
  | 'PLACE_33'
  | 'PLACE_34'
  | 'PLACE_35'
  | 'PLACE_36'
  | 'PLACE_37'
  | 'PLACE_38'
  | 'PLACE_39'
  | 'PLACE_40'
  | 'PLACE_41'
  | 'PLACE_42'
  | 'PLACE_43'
  | 'PLACE_44'
  | 'PLACE_45'
  | 'PLACE_46'
  | 'PLACE_47'
  | 'PLACE_48'
  | 'PLACE_49'
  | 'PLACE_50';

export type VoidReason = 'OUTCOME_VOID_UNKNOWN'
  | 'FIXTURE_CANCELLATION'
  | 'PALPABLE_ERROR'
  | 'NON_RUNNER'
  | 'MITING_ABANDONED'
  | 'LATE_BET'
  | 'OUTCOME_VOID_REASON_6'
  | 'OUTCOME_VOID_REASON_7'
  | 'OUTCOME_VOID_REASON_8'
  | 'OUTCOME_VOID_REASON_9'
  | 'OUTCOME_VOID_REASON_10'
  | 'OUTCOME_VOID_REASON_11'
  | 'OUTCOME_VOID_REASON_12'
  | 'OUTCOME_VOID_REASON_13'
  | 'OUTCOME_VOID_REASON_14'
  | 'OUTCOME_VOID_REASON_15'
  | 'OUTCOME_VOID_REASON_16'
  | 'OUTCOME_VOID_REASON_17'
  | 'OUTCOME_VOID_REASON_18'
  | 'OUTCOME_VOID_REASON_19'
  | 'OUTCOME_VOID_REASON_20'
  | 'OUTCOME_VOID_REASON_21'
  | 'OUTCOME_VOID_REASON_22';

export type OutcomeSinglesByEventType = {
  betsCount: number;
  betsCountSingle: number;
  betsTotal: number;
  currentPrice: number;
  feedPrice: number;
  firstPrice: number;
  liabilityRisk: number;
  liabilityRiskSingle: number;
  manualMode: boolean;
  traderName: string | null | undefined;
  outcome: OutcomeType;
};

export type MarketListType = {
  id?: string;
  type?: string;
  typeDescription?: string;
  marketTypeGroup?: string;
  description: string;
  period: string | null | undefined;
  periodId: string | null | undefined;
  opponent?: string;
  opponentId?: string;
  cutoffTime: string | null | undefined;
  homeSpread?: number;
  awaySpread?: number;
  status?: string;
  outcomes: Array<OutcomeType>;
  version?: string;
  index?: number;
  periodInRunning?: boolean;
  priceTraderMode?: boolean;
  pricePolling: Polling | null | undefined;
  hideMode?: boolean;
};

export type EventType = {
  id: string;
  startDate: string;
  description: string | null | undefined;
  inRunningCalendar: string | null | undefined;
  isInRunningDelayInherited: string | null | undefined;
  opponentHomeId: string;
  opponentHome: string;
  opponentAwayId: string;
  opponentAway: string;
  inRunningDelay: string | null | undefined;
  neutralVenue: string | null | undefined;
  tieBreak: string | null | undefined;
  traderComment: string | null | undefined;
  type: string | null | undefined;
  version: string | null | undefined;
  comment: string | null | undefined;
  scoreTraderMode: string | null | undefined;
  sportId: string;
  regionId: string | null | undefined;
  leagueId: string | null | undefined;
  bestOfSets: string | null | undefined;
  sportDescription: string | null | undefined;
  regionDescription: string | null | undefined;
  leagueDescription: string | null | undefined;
  marketStatus: string | null | undefined;
  feedCode: string;
  isLive: boolean;
  clock: {
    id: string;
    offset: string;
    period: { description: string; id: string };
    status: string;
  };
  marginValue: number;
  isAutoMargin: boolean;
  hideMode: boolean;
  manualCreated: boolean;
  mainEventId: string;
  othersCopyEvents: Array<string>;
  eachWayTerms: string;
  cashoutEnabled: boolean;
  noAdScoring: boolean;
  toleranceRate: number;
  isToleranceRateInherited: boolean;
  cashoutInherited: boolean;
  isPrematchVisible: boolean;
};

export type IdValue = {
  readonly id: string;
  readonly value: number;
  readonly percent: number;
};

export type MarketStatusCount = {
  readonly open: number;
  readonly suspended: number;
  readonly closed: number;
  readonly resulted: number;
  readonly settled: number;
};

export type EventListTablRowType = {
  startDate: string;
  readonly description: string;
  readonly id: string;
  readonly leagueDescription: string;
  readonly leagueId: string;
  readonly marketStatus: string;
  readonly regionDescription: string;
  readonly regionId: string;
  readonly sportDescription: string;
  readonly sportId: string;
  readonly marketStatusCount: MarketStatusCount;
  readonly isLive?: boolean;
  readonly manualMode: Array<string>;
  readonly feedCode: string;
};

export type OriginsLimitValue = {
  currencyId: string,
  value: number,
}

export type OriginType = {
  id: string;
  description: string;
  webSite: string;
  priceLineId: string;
  defaultLimitGroupId: string;
  inrunningSettings: {
    inRunningDelay: number;
    toleranceRate: number;
    acceptPriceMoveUp: boolean;
    acceptPriceMoveDown: boolean;
  };
  cashoutSettings: {
    multiBetFactor: number;
    maxOutLimit: number;
    prematchSingleMargin: number;
    prematchOutrightSingleMargin: number;
    liveSingleMargin: number;
    prematchMultiMargin: number;
    prematchOutrightMultiMargin: number;
    liveMultiMargin: number;
  };
  version: string;
  defaultMarketMargin: number;
  defaultOrigin: boolean;
  limits: Array<{
    limitCode: string,
    values: Array<OriginsLimitValue>,
  }>,
  maxOutcomeCount: number;
  parentId: string;
  modifiedLimitGroups: boolean;
};

export type OpponentType = {
  description: string;
  eventPathId: string;
  id: string;
  sportCode: string;
  type: 'TEAM' | 'PLAYER' | 'HORSE' | 'DOG';
};

export type MarketStatusType = $Keys<typeof MARKET_STATUS_ENUM>;

export type SingleByEventMarketType = {
  market: {
    description: string;
    id: string;
    status: MarketStatusType;
    isOutright: boolean;
    periodDescription: string;
    periodId: string;
    typeId: string;
    bookType: $Keys<typeof BOOK_TYPES>;
  };
  marketManualMode: boolean;
  marketManualCreated: boolean;
  marketCashoutInherited: boolean;
  // marketCashoutEnabled: boolean; *** can use in future ***
  showInIRM: boolean;
  isSpecialMarket: boolean;
  isBetBuilderMarket: boolean;
  isOriginMarginOverride: boolean;
  outcomes: Array<OutcomeSinglesByEventType>
};

export type SingleByEventClock = {
  id: string;
  offset: string;
  period: { description: string; id: string };
  status: string;
};

export type SingleByEventResponse = {
  clock: SingleByEventClock;
  event: {
    description: string;
    id: string;
    startDate: string;
  };
  markets: Array<SingleByEventMarketType>;
  totalScore: string;
};

export enum BetTypeTypeEnum {
  Bet_Any = 'Bet_Any',
  Bet_Single = 'Bet_Single',
  Bet_Multiple = 'Bet_Multiple',
  Bet_System = 'Bet_System',
  Bet_BetBuilder = 'Bet_BetBuilder',
  Bet_Trader = 'Bet_Trader',
}

export type BetTypeType =
  | 'Bet_Any'
  | 'Bet_Single'
  | 'Bet_Multiple'
  | 'Bet_System'
  | 'Bet_BetBuilder'
  | 'Bet_Trader';

export type BetStatusType = keyof typeof BetStatusesEnum

export type BetBookType = 'BOOKTYPE_PRE_MATCH' | 'BOOKTYPE_LIVE' | 'BOOKTYPE_OUTRIGHT';

export type BetsRequest = {
  minStake?: number;
  maxStake?: number;
  betTypes?: Array<keyof typeof BetTypeTypeEnum>;
  betStatuses?: Array<keyof typeof BetStatusesEnum>;
  voidReasons?: Array<string>;
  rejectReasons?: Array<string>;
  userIds?: Array<string>;
  bookTypes?: Array<BetBookType>;
  factorGroupIds?: Array<string>;
  limit: number;
  offset?: number;
  originId?: string | void;
  startDate?: string;
  endDate?: string;
  sortBy?: Array<keyof typeof BetsSortByEnum>;
  sortDir?: 'ASC' | 'DESC';
  eventIds?: Array<string>;
  outcomeIds?: Array<string>;
  betRejectReasons?: Array<string>;
  sportIds?: Array<string>;
  marketTypeId?: string;
  periodTypeId?: string;
  hideTestAccounts?: string;
  isOutright?: string;
};

export type BetType = {
  id: string;
  betSystemGroupId: string | '0';
  status: BetStatusType;
  voidReason: string | null | undefined;
  rejectReason: string | null | undefined;
  stake: number;
  stakeUSD: number;
  payout: number;
  payoutUSD: number;
  type: 'Single' | 'Multiple' | 'System';
  date: string;
  rejectReasonDescription: string;
  firstBets: boolean;
  outcomes: Array<{
    bookType: 'BOOKTYPE_PRE_MATCH' | 'BOOKTYPE_LIVE' | 'BOOKTYPE_OUTRIGHT';
    description: string;
    id: string;
    price: number;
    market: {
      description: string;
      id: string;
      periodDescription: string;
      periodId: string;
      typeId: string;
    };
    event: {
      description: string;
      id: string;
      startDate: string;
    };
    league: {
      description: string;
      id: string;
    };
    region: {
      description: string;
      id: string;
    };
    sport: {
      description: string;
      id: string;
    };
    result: 'WIN' | 'LOSE' | 'VOID' | 'NO_RESULT';
  }>;
  user: {
    currency: string;
    firstName: string;
    id: string;
    lastName: string;
    username: string;
    origin: {
      color: string;
      id: string;
      description: string;
    };
    factorGroup: {
      color: string;
      id: string;
      description: string;
    };
    overrideLimits?: boolean
  };
  traderName: string;
  isEachWay: boolean;
  isSettledByPortal: boolean;
  traderVoidName: string;
};

export type SettledByPlayersRequestType = {
  startDate?: string;
  endDate?: string;
  originIds?: string[];
  sportIds?: string;
  playerFactorGroupId?: string;
  sortBy?: 'profit' | 'payout' | 'bets' | 'turnover';
  sortDir?: 'ASC' | 'DESC';
  limit?: number;
  offset?: number;
};

export type BetsColumnLabelType =
  | 'Date'
  | 'Player'
  | 'Outcomes'
  | 'Stake'
  | 'Price'
  | 'Type'
  | 'Winning'
  | 'Status'
  | 'Accepted'
  | 'Actions';

export enum BetsColumnLabelEnum {
  Date = 'Date',
  Player = 'Player',
  Outcomes = 'Outcomes',
  Stake = 'Stake',
  Price = 'Price',
  Type = 'Type',
  Winning = 'Winning',
  Status = 'Status',
  Actions = 'Actions',
}

export enum BetsTableFiltersEnum {
  userId = 'userId',
  eventId = 'eventId',
  betType = 'betType',
  betAccepted = 'betAccepted',
  bookType = 'bookType',
  betStatuses = 'betStatuses',
  stake = 'stake',
  period = 'period',
  sports = 'sports',
  marketType = 'marketType',
  hideTestAccounts = 'hideTestAccounts',
  originIds = 'originIds',
  showOnlyOutrights = 'showOnlyOutrights',
  betId = 'betId',
  betRejectReasons = 'betRejectReasons',
  factorGroupIds = 'factorGroupIds',
}

export enum BetsTableFiltersEnumNew {
  userId = 'userId',
  eventId = 'eventId',
  betType = 'betType',
  betAccepted = 'betAccepted',
  bookType = 'bookType',
  betStatuses = 'betStatuses',
  stake = 'stake',
  period = 'period',
  sports = 'sports',
  hideTestAccounts = 'hideTestAccounts',
  originIds = 'originIds',
  showOnlyOutrights = 'showOnlyOutrights',
  betId = 'betId',
  betRejectReasons = 'betRejectReasons',
  factorGroupIds = 'factorGroupIds',
  startDate = 'startDate',
  endDate = 'endDate',
  marketType = 'marketType',
}

export enum BetsTableFiltersStringEnum {
  userId = 'userId',
  eventId = 'eventId',
  betAccepted = 'betAccepted',
  bookType = 'bookType',
  period = 'period',
  marketType = 'marketType',
  betId = 'betId',
  startDate = 'startDate',
  endDate = 'endDate',
}

export enum BetsTableFiltersStringArrayEnum {
  originIds = 'originIds',
  factorGroupIds = 'factorGroupIds',
  sports = 'sports',
  betRejectReasons = 'betRejectReasons',
}

export enum BetsTableFiltersBooleanEnum {
  hideTestAccounts = 'hideTestAccounts',
  showOnlyOutrights = 'showOnlyOutrights',
}

export type PlayerFactorGroup = typeof UNKNOWN_PLAYER_FACTOR_GROUP;

export type MarketCreateType = {
  marketTypeId: string;
  periodId: string;
  status: string;
  homeSpread: string;
  awaySpread: string;
  description: string;
  cutoffTime: Date | null | undefined;
  cashoutInherited: boolean;
  cashoutEnabled: boolean;
  outcomes: Array<{
    spread?: string;
    description: string;
    opponentId: string | null | undefined;
    price?: number | string;
  }>;
  opponentId: string | null | undefined;
  hideMode: boolean;
};

export type MarketType = Omit<MarketCreateType, 'outcomes'> & {
  id: string;
  outcomes: Array<{
    description: string;
    price: string | number | null;
    opponentId: string | null | undefined;
    id: string;
    hideModeTrader: boolean;
    result: string;
    voidReason: string;
    ordinalPosition: string;
  }>;
  isBetBuilderMarket?: boolean;
};

export type ScoreForPeriodsType = {
  periodScores: Array<PeriodScoresType>;
  homeOpponent: ScoreOpponentForPeriod;
  awayOpponent: ScoreOpponentForPeriod;
};

export type PeriodScoresType = {
  periodId: string;
  period: string;
  parentPeriodId: string;
  isActive: boolean;
  scores: Array<ScoresForPeriodType>;
};

export type OpponentsScore = {
  id: string;
  value: number;
  canIncrement: boolean;
  canDecrement: boolean;
  canEndMatch: boolean;
};

export type ScoresForPeriodType = {
  typeId: string;
  typeDescription: string;
  homeScore: OpponentsScore;
  awayScore: OpponentsScore;
};

export type ScoreOpponentForPeriod = {
  id: string;
  description: string;
};

export type PeriodsScoresTreeNodeType = {
  node: PeriodScoresType;
  children: Array<PeriodsScoresTreeNodeType>;
};

export type PeriodsTreeNodeType = {
  node: PeriodType;
  children: Array<PeriodsTreeNodeType>;
  opened: boolean;
};

export type ModalPeriodsTreeNodeType = {
  node: PeriodType;
  children: Array<ModalPeriodsTreeNodeType>;
  opened: boolean;
  checked: boolean;
};

export type MarketsForCreationType = {
  id: string;
  description: string;
  ordinalPosition: 0;
  typeGroup: string;
  isFavorite: true;
  extraFields: Array<{
    id: string;
    description: string;
    type: string;
    mayBeEmpty: true;
    defaultValue: string;
  }>;
  periodId: string;
  periodDescription: string;
  periodSequence: number;
  allowMultiMarkets: boolean;
  numberOfMarkets?: number;
};

export type PresetsType = {
  id: string;
  description: string;
  items: Array<{
    marketTypeId: string;
    periodId: string;
  }>;
};

export type MarketUpdateResponse = {
  id: string;
  eventId: string;
  description: string;
  marketTypeId: string;
  periodId: string;
  status: string;
  homeSpread: number;
  awaySpread: number;
  cutoffTime: string;
  opponentId: string;
  hideMode: boolean;
  manualMode: boolean;
  manualCreated: boolean;
  manualStatus: string;
  version: string;
  outcomes: Array<{
    description: string;
    price: number | null;
    opponentId: string | null | undefined;
    id: string;
    hideModeFeed: boolean;
    hideModeTrader: boolean;
    result: string;
    voidReason: string;
    ordinalPosition: string;
    manualMode: boolean;
  }>;
  cashoutInherited: boolean;
  cashoutEnabled: boolean;
};

export type Market = {
  id: string;
  description: string;
  status: MarketStatusType;
  periodDescription: string;
  bookType: $Keys<typeof BOOK_TYPES>;
};

export type UserType = {
  username: string;
  jwt: string;
  permissions: UserPermissions;
};

export type UserPermissions = {
  allowPermissions: Array<any>;
  denyPermissions: Array<any>;
  roles: Array<string>;
};

export type EventOutletContextType = {
  event?: EventType;
  hideStatusState?: boolean;
  eventId?: string;
  outcomeId?: string;
};

export type EventPathOutletContextType = {
  reload: () => void;
};

export type QueryStateType = {
  risk?: string;
  manualMode?: string | string[];
  marketStatuses?: string | string[];
  search?: string;
  betType?: BetTypeType;
  betStatuses?: Array<BetStatusType>;
  bookType?: BetBookType;
  sortBy?: string;
  sortDir?: 'DESC' | 'ASC' | '';
  startDate?: string;
  liveMode?: boolean;
  limit?: number | string;
  endDate?: string;
  period?: string;
  startDateFrom?: string;
  startDateTo?: string;
  sports?: Array<string>;
  sportIds?: string;
  regionIds?: string;
  leagueIds?: string;
  page?: string;
  originId?: string;
  originIds?: string[];
  betRejectReasons?: string[];
  outcomeId?: string;
  betId?: string;
  userId?: string;
  eventId?: string;
  minStake?: string;
  maxStake?: string;
  marketPeriod?: string;
  marketType?: string;
  hideTestAccounts?: string;
  showOnlyOutrights?: string;
  betAccepted?: string;
  showLifetime?: string;
  factorGroupIds?: string[];
  liveOnly?: boolean;
  withBetsOnly?: boolean;
};

export type ModalStateType = {
  showModal: boolean;
};

export type SelectedPeriodsStateType = {
  periods: Array<string>;
};

export type SelectedMarketsStateType = {
  markets: Array<{
    marketId: string;
    periodId: string;
    numberOfMarkets?: number;
  }>;
};

export type PresetStateType = {
  presetsList: Array<PresetsType>;
};

export type LeagueLimitGroup = {
  id: string;
  description: string;
  limits: {
    maxPayoutLiveMultiple: number;
    maxPayoutLiveSingle: number;
    maxPayoutMultiple: number;
    maxPayoutSingle: number;
    maxStakeLiveMultiple: number;
    maxStakeLiveSingle: number;
    maxStakeMultiple: number;
    maxStakeSingle: number;
  };
};

export type TPersonalLimitGroup = {
  limits: Array<LeagueLimitGroup>,
  originId: string;
  enabled: boolean;
}

export type MarketOverrideType = {
  marketTypeId: string;
  periodId: string;
  description: string;
  ordinalPosition: number;
}

export type PriceLocalMarketsType = {
  outcomeId: number | string,
  currentPrice: number,
  localPrice: number,
  feedPrice: number | string,
  delta: number,
  correct: boolean,
}

export enum FieldTypesEnum {
  TEXT = 'text',
  NUMBER = 'number',
  CHECKBOX = 'checkbox',
  TEXTAREA = 'textArea',
  DOUBLE = 'double'
}

export type VoidReasonsItem = {
  id: string,
  description: string,
  value: VoidReason,
}

export type TOS = {[key: string]: string }

export type TSettleByPlayerPerformance = {
  totalBets: number,
  totalMargin: number,
  totalPayoutUSD: number,
  totalProfitUSD: number,
  totalTurnoverUSD: number,
}

export type TSettleByPlayersBets = {
  bets: string,
  margin: number,
  payout: number,
  payoutUSD: number,
  profit: number,
  profitUSD: number,
  turnover: number,
  turnoverUSD: number,
  user: {
    id: string,
    username: string,
    firstName: string,
    lastName: string,
    currency: string,
    origin: {
      id: string,
      name: string,
      color: string
    },
    userFactorGroup: {
      id: string,
      name: string,
      color: string
    },
    performance?: {
      totalBets: number,
      totalTurnover: number,
      totalPayout: number,
      totalProfit: number,
      totalTurnoverUSD: number,
      totalPayoutUSD: number,
      totalProfitUSD: number,
      totalMargin: number
    }
    overrideLimits?: boolean,
  }
}

export interface TBetsTableRow {
  bet: BetType;
  columns: Array<BetsColumnEnum>;
  voidReasonList: Array<{
    id: string;
    description: string;
    value: string;
  }>;
  className: string | null;
  isBetSelected: boolean;
  selectBets: (bet: BetType) => void;
  showCopyButtons?: {
    userId?: boolean;
    betId?: boolean;
    userName?: boolean;
  };
  parentDomId: string;
  activeColumnsList: BetsColumnEnum[],
  withFilters: boolean;
  liveMode: boolean;
}

export interface IOutcomeInfo {
  sportDescription?: string;
  leagueDescription?: string;
  eventDescription: string;
  eventId: string;
  outcomeMarketDescription?: string;
  outcomeMarketPeriodDescription?: string;
  outcomeDescription?: string;
  outcomePrice?: number;
  outcomeId?: string;
  result?: keyof typeof OutcomeResultsEnum;
  isEachWay?: boolean;
  parentDomId: string;
  withFilters: boolean;
  activeColumnsList: BetsColumnEnum[],
}

export interface IOriginMargin {
  eventPathId: string,
  periodId: string,
  marketTypeId: string,
  margin: string,
  enable: boolean,
  id?: string,
}

export interface ISport {
  id: string,
  description: string,
  realSportId: string,
  sportCode: string,
  countDown: true,
  version: string,
  enableInherited: boolean,
  enableEnabled: boolean
}

export interface IRegion {
  id: string,
  description: string,
  sportId: string,
  sportDescription: string,
  version: string
}

export interface ILeague {
  id: string,
  description: string,
  regionId: string,
  regionDescription: string,
  version: string
}

export interface IEventPath {
  name: string,
  id: string,
  sportCode: string,
  regions: [
    {
      name: string,
      id: string,
      leagues: [
        {
          name: string,
          id: string
        }
      ]
    }
  ]
}

export enum ResultReasonSettingsEnum {
  RESULT_REASON_UNKNOWN = 'RESULT_REASON_UNKNOWN',
  RESULT_REASON_FEED = 'RESULT_REASON_FEED',
  RESULT_REASON_EARLY_PAYOUT = 'RESULT_REASON_EARLY_PAYOUT',
  RESULT_REASON_BORE_DRAW = 'RESULT_REASON_BORE_DRAW'
}

export interface ILeagueFindById {
  cashoutEnabled: boolean,
  cashoutInherited: boolean,
  description: string,
  enableEnabled: boolean,
  enableInherited: boolean,
  id: string,
  limitGroupId: string,
  limitGroupInherited: boolean,
  regionDescription: string,
  regionId: string,
  sportDescription: string,
  sportId: string,
  version: string,
  resultReasons: ResultReasonSettingsEnum[]
}

export type UserOutletContextType = {
  userId: string,
};
