import { SemanticCOLORS } from 'semantic-ui-react';
import { BetTypeTypeEnum } from './types';

export const dictToOptions = (obj: { [key: string]: string }) =>
  Object.keys(obj).reduce((arr, key) => {
    arr.push({
      text: obj[key],
      value: key,
    });

    return arr;
  }, []);

/**================== API ==================*/

export const API_PHOENIX = '/v1/DealerAPI';

/* Auth */
export const API_PHOENIX_LOGIN = `${API_PHOENIX}/login`;
export const API_PHOENIX_LOGOUT = `${API_PHOENIX}/logout`;
export const API_PHOENIX_TOKEN_VALIDATE = `${API_PHOENIX}/tokenValidate`;

/* Bet */
export const API_BET = `${API_PHOENIX}/bet`;
export const API_BET_PLACE = `/v2/DealerAPI/bet/place`;

export const API_BETS = `${API_PHOENIX}/bets`;

/* Event */
export const API_EVENT = `${API_PHOENIX}/event`;
export const API_EVENT_CREATE = `${API_EVENT}/create`;
export const API_EVENT_UPDATE = `${API_EVENT}/update`;
export const API_EVENT_COPY = `${API_EVENT}/copy`;

export const API_EVENT_BET_RESTRICTIONS = `${API_EVENT}/betRestrictions`;
export const API_EVENT_BET_RESTRICTIONS_UPDATE = `${API_EVENT}/betRestrictions/update`;

export const API_EVENTS = `${API_PHOENIX}/events`;
export const API_EVENTS_FIND = `${API_EVENTS}/find`;
export const API_EVENTS_FIND_BY_ID = `${API_EVENTS}/findById`;

/* Market */
export const API_MARKET = `${API_PHOENIX}/market`;
export const API_MARKET_CREATE = `${API_PHOENIX}/market/create`;
export const API_MARKET_FIND = `${API_PHOENIX}/market/find`;

export const API_MARKET_PRESET_LIST = `${API_PHOENIX}/market/preset/list`;
export const API_MARKET_PRESET_CREATE = `${API_PHOENIX}/market/preset/create`;
export const API_MARKET_PRESET_DELETE = `${API_PHOENIX}/market/preset/delete`;
export const API_MARKET_PRESET_EDIT = `${API_PHOENIX}/market/preset/edit`;

export const API_MARKET_TEMPLATE_CREATE = `${API_PHOENIX}/market/template/create`;

/* Market Type */
export const API_MARKET_TYPE = `${API_PHOENIX}/marketType`;
export const API_MARKET_TYPE_OVERRIDE_LIST = `${API_MARKET_TYPE}/override/list`;
export const API_MARKET_TYPE_OVERRIDE_UPDATE = `${API_MARKET_TYPE}/override/update`;
export const API_MARKET_TYPE_OVERRIDE_RESET = `${API_MARKET_TYPE}/override/reset`;
export const API_MARKET_TYPE_OUTCOME_LIST = `${API_MARKET_TYPE}/outcomeList`;
export const API_MARKET_TYPE_FIND = `${API_MARKET_TYPE}/find`;
export const API_MARKET_TYPE_TEMPLATE_FIND = `${API_MARKET_TYPE}/template/find`;

/* Markets */
export const API_MARKETS = `${API_PHOENIX}/markets`;
export const API_MARKETS_UPDATE = `${API_MARKETS}/update`;

/* Period */
export const API_PERIOD = `${API_PHOENIX}/period`;
export const API_PERIOD_REVERT = `${API_PERIOD}/revert`;
export const API_PERIOD_FIND = `${API_PERIOD}/find`;

/* Score */
export const API_SCORE = `${API_PHOENIX}/score`;
export const API_SCORE_FIND = `${API_SCORE}/find`;
export const API_SCORE_UPDATE = `${API_SCORE}/update`;

/* Event Path Tree */
export const API_EVENT_PATH = `${API_PHOENIX}/eventpath`;
export const API_EVENT_PATH_UPDATE = `${API_PHOENIX}/eventpath/update`;
export const API_EVENT_PATH_CREATE = `${API_PHOENIX}/eventpath/create`;

/* Sport */
export const API_SPORT_UPDATE = `${API_PHOENIX}/sport/update`;
export const API_SPORT_CREATE = `${API_PHOENIX}/sport/create`;

/* Sports | Regions | Leagues */
export const API_SPORTS_FIND = `${API_PHOENIX}/sports/find`;
export const API_SPORTS_FIND_BY_ID = `${API_PHOENIX}/sports/findById`;
export const API_REGIONS_FIND = `${API_PHOENIX}/regions/find`;
export const API_REGIONS_FIND_BY_ID = `${API_PHOENIX}/regions/findById`;
export const API_LEAGUES_FIND = `${API_PHOENIX}/leagues/find`;
export const API_LEAGUES_FIND_BY_ID = `${API_PHOENIX}/leagues/findById`;

/* Opponent */
export const API_OPPONENT_FIND = `${API_PHOENIX}/opponent/find`;
export const API_OPPONENT_CREATE = `${API_PHOENIX}/opponent/create`;
export const API_OPPONENT_UPDATE = `${API_PHOENIX}/opponent/update`;

/* Origin */
export const API_ORIGIN = `${API_PHOENIX}/origin`;
export const API_ORIGIN_LIST = `${API_PHOENIX}/origin/list`;
export const API_ORIGIN_RESET = `${API_PHOENIX}/origin/reset`;
export const API_ORIGIN_UPDATE = `${API_PHOENIX}/origin/update`;
export const API_ORIGIN_MARGINS = `${API_PHOENIX}/origin/margins/find`;
export const API_ORIGIN_MARGINS_UPDATE = `${API_PHOENIX}/origin/margins/update`;

/* Other */
export const API_VOID_REASON_LIST = `${API_PHOENIX}/voidReason/list`;
export const API_TRADER_VOID_REASON_LIST = `${API_PHOENIX}/traderVoidReason/list`;
export const API_VOID_BETS = `${API_PHOENIX}/voidBets`;
export const API_SINGLES_RISK = `${API_PHOENIX}/rm/singlesRisk`;
export const API_SINGLES_BY_EVENT = `${API_PHOENIX}/rm/singlesByEvent`;
export const API_IN_RUNNING_DELAY = `${API_PHOENIX}/inRunningDelay`;
export const API_PLAYER_FACTOR_GROUPS = `${API_PHOENIX}/playerFactorGroups`;
export const API_PLAYER_BY_ID = `${API_PHOENIX}/playerById`;
export const API_SETTLE_BY_PLAYER = `${API_PHOENIX}/settleByPlayer`;
export const API_SETTLE_BETS = `${API_PHOENIX}/settleBets`;
export const API_PRICE_CREATE = `${API_PHOENIX}/price/create`;
export const API_PRICES_CREATE = `${API_PHOENIX}/prices/create`;
export const API_PRICELINE_UPDATE = `${API_PHOENIX}/priceline/update`;
export const API_CLOCK_UPDATE = `${API_PHOENIX}/clock/update`;
export const API_CURRENCY_LIST = `${API_PHOENIX}/currency/list`;
export const API_EVENT_PATH_TREE_FIND = `${API_PHOENIX}/eventpathtree/find`;

export const API_ACCOUNT = `${API_PHOENIX}/account`;

/**================== Paths ==================*/

export const HOME_PATH = '/';
export const EMPTY_PATH = '';
export const ANOTHER_PATH = '*';
export const EVENTS_PATH = '/events';
export const EVENT_CREATE_PATH = '/event-create';
export const EVENT_EDIT_PATH = '/event-edit';
export const EVENT_EDIT_WITH_ID_PATH = `${EVENT_EDIT_PATH}/:eventId`;
export const EVENT_MARKETS_PATH = 'markets';
export const EVENT_BET_RESTRICTIONS_PATH = 'bet-restrictions';
export const EVENT_IRM_PATH = 'irm';
export const EVENT_BETS_PATH = 'bets';
export const SELECTION_PATH = '/selection';
export const SINGLES_PATH = '/singles';
export const MULTIPLES_PATH = '/multiples';
export const BETS_PATH = '/bets';
export const SETTLED_PATH = '/settled-by-players';
export const LIMIT_GROUPS_PATH = '/limit-groups';
export const ORIGIN_SETTINGS_PATH = '/origin-settings';
export const NEW_LEAGUES_PATH = '/new-leagues';
export const USER_PATH = '/user';
export const USER_WITH_ID_PATH = `${USER_PATH}/:userId`;
export const EVENT_TREE_PATH = '/eventpaths';
export const EVENT_TREE_SPORT_PATH = 'sport/:sportId';
export const EVENT_TREE_NEW_REGION_PATH = 'region/new';
export const EVENT_TREE_REGION_PATH = 'region/:regionId';
export const EVENT_TREE_NEW_LEAGUE_PATH = 'league/new';
export const EVENT_TREE_LEAGUE_PATH = 'league/:leagueId';
export const MARKET_TYPE_OVERRIDE_PATH = '/market-type-override';
export const NEW_BETS = '/new-bets';

export const DOCUMENT_TITLES_BY_PATH = {
  [HOME_PATH]: 'Phoenix admin',
  [EVENTS_PATH]: 'Events',
  [EVENT_CREATE_PATH]: 'Create event',
  [EVENT_EDIT_PATH]: 'Edit event',
  [SELECTION_PATH]: 'Selection',
  [SINGLES_PATH]: 'Singles',
  [MULTIPLES_PATH]: 'Multiples',
  [BETS_PATH]: 'Bets',
  [SETTLED_PATH]: 'Settled by players',
  [LIMIT_GROUPS_PATH]: 'Limit groups',
  [ORIGIN_SETTINGS_PATH]: 'Origin settings',
  [NEW_LEAGUES_PATH]: 'New leagues',
  [USER_PATH]: 'User',
  [EVENT_TREE_PATH]: 'Event path tree',
  [MARKET_TYPE_OVERRIDE_PATH]: 'Market type override'
}

export const MARKET_STATUS_ARRAY = [
  { id: 'OPEN', description: 'Opened' },
  { id: 'SUSPENDED', description: 'Suspended' },
  { id: 'CLOSED', description: 'Closed' },
  { id: 'SETTLED', description: 'Settled' },
  { id: 'MARKET_STATUS_UNKNOWN', description: 'No markets' }
];
MARKET_STATUS_ARRAY.map<{
  value: string;
  text: string;
}>(({ id, description }) => ({
  value: id,
  text: description,
}));
export const MARKET_STATUS_DROPDOWN_OPTIONS = [
  { value: 'OPEN', text: 'Opened' },
  { value: 'SUSPENDED', text: 'Suspended' },
  { value: 'CLOSED', text: 'Closed' },
  { value: 'SETTLED', text: 'Settled' }
];

export const OUTCOME_ORDINAL_POSITION_DROPDOWN_OPTIONS = [
  { value: 1, text: '1' },
  { value: 2, text: '2' },
  { value: 3, text: '3' }
];

export const BET_TYPE_DROPDOWN_OPTIONS = [
  { value: BetTypeTypeEnum.Bet_Any, text: 'All bets' },
  { value: BetTypeTypeEnum.Bet_Single, text: 'Single bets' },
  { value: BetTypeTypeEnum.Bet_Multiple, text: 'Multiple bets' },
  { value: BetTypeTypeEnum.Bet_System, text: 'System bets' },
  { value: BetTypeTypeEnum.Bet_BetBuilder, text: 'Single bets (Betbuilder)' },
  { value: BetTypeTypeEnum.Bet_Trader, text: 'Manual bets (By trader)' }
];

export const BET_ACCEPTED_STATUS_DROPDOWN_OPTIONS = [
  { value: 'Success', text: 'Success' },
  { value: 'Failure', text: 'Failure' }
];

export const MANUAL_MODE_ARRAY = [
  { id: 'EVENTS_MANUALLY_CREATED', description: 'Manually created events' },
  { id: 'FEED_EVENTS_MANUALLY_CREATED_MARKET', description: 'Feed events with manually created markets' },
  { id: 'FEED_EVENTS_MANUAL_MARKET', description: 'Feed events with markets on manual mode' },
  { id: 'EVENTS_MANUALLY_RESULTED_MARKET', description: 'Events with manually resulted markets' },
];

export const MANUAL_MODE_FILTER_OPTIONS = MANUAL_MODE_ARRAY.map<{
  value: string;
  text: string;
}>(({ id, description }) => ({
  value: id,
  text: description,
})) as Array<{
  disabled?: boolean;
  labelColor?: SemanticCOLORS;
  value: string;
  text: string;
}>;

enum MarketStatusEnum {
  OPEN = 'OPEN',
  SUSPENDED = 'SUSPENDED',
  CLOSED = 'CLOSED',
  SETTLED = 'SETTLED',
  RESULTED = 'RESULTED',
  NO_MARKETS = 'NO_MARKETS',
}

export const MARKET_STATUS_ENUM = {
  OPEN: 'OPEN',
  SUSPENDED: 'SUSPENDED',
  CLOSED: 'CLOSED',
  SETTLED: 'SETTLED',
  RESULTED: 'RESULTED',
  NO_MARKETS: 'NO_MARKETS',
};

export const MARKET_STATUSES: {
  [key in MarketStatusEnum]: { label: string; color: SemanticCOLORS };
} = {
  OPEN: { label: 'Open', color: 'green' },
  SUSPENDED: { label: 'Suspended', color: 'orange' },
  CLOSED: { label: 'Closed', color: 'red' },
  SETTLED: { label: 'Settled', color: 'blue' },
  RESULTED: { label: 'Resulted', color: 'brown' },
  NO_MARKETS: { label: 'No markets', color: 'black' },
};

export const MARKET_STATUSES_OPTIONS = [
  {
    text: MARKET_STATUSES[MARKET_STATUS_ENUM.OPEN].label,
    value: MARKET_STATUS_ENUM.OPEN,
    labelColor: MARKET_STATUSES[MARKET_STATUS_ENUM.OPEN].color,
  },
  {
    text: MARKET_STATUSES[MARKET_STATUS_ENUM.SUSPENDED].label,
    value: MARKET_STATUS_ENUM.SUSPENDED,
    labelColor: MARKET_STATUSES[MARKET_STATUS_ENUM.SUSPENDED].color,
  },
  {
    text: MARKET_STATUSES[MARKET_STATUS_ENUM.CLOSED].label,
    value: MARKET_STATUS_ENUM.CLOSED,
    labelColor: MARKET_STATUSES[MARKET_STATUS_ENUM.CLOSED].color,
  },
  {
    text: MARKET_STATUSES[MARKET_STATUS_ENUM.SETTLED].label,
    value: MARKET_STATUS_ENUM.SETTLED,
    labelColor: MARKET_STATUSES[MARKET_STATUS_ENUM.SETTLED].color,
  },
  {
    text: MARKET_STATUSES[MARKET_STATUS_ENUM.NO_MARKETS].label,
    value: 'MARKET_STATUS_UNKNOWN',
    labelColor: MARKET_STATUSES[MARKET_STATUS_ENUM.NO_MARKETS].color,
  }
];

export enum OutcomeResultsEnum {
  WIN = 'WIN',
  LOSE = 'LOSE',
  VOID = 'VOID',
  NO_RESULT = 'NO_RESULT',
  SET_PLACE = 'SET_PLACE',
}

const MIN_NUMBER_OF_PLACES = 2;
const MAX_NUMBER_OF_PLACES = 51;

for (let i = MIN_NUMBER_OF_PLACES; i < MAX_NUMBER_OF_PLACES; i++) {
  const new_key = 'PLACE_' + i;
  OutcomeResultsEnum[new_key] = new_key;
}

export const OUTCOME_RESULTS = {
  [OutcomeResultsEnum.WIN]: {
    label: 'Win',
    color: 'green',
    backgroundColor: 'rgba(33, 186, 69, .05)',
  },
  [OutcomeResultsEnum.LOSE]: {
    label: 'Lose',
    color: 'red',
    backgroundColor: 'rgba(219, 40, 40, .05)',
  },
  [OutcomeResultsEnum.VOID]: {
    label: 'Void',
    color: 'violet',
    backgroundColor: 'rgba(88, 41, 187, .05)',
  },
  [OutcomeResultsEnum.NO_RESULT]: {
    label: 'No Result',
    color: 'grey',
    backgroundColor: 'transparent',
  },
  [OutcomeResultsEnum.NO_RESULT]: {
    label: 'No Result',
    color: 'orange',
    backgroundColor: 'transparent',
  },
};

for (let i = MIN_NUMBER_OF_PLACES; i < MAX_NUMBER_OF_PLACES; i++) {
  OUTCOME_RESULTS['PLACE_' + i] = {
    label: 'Place ' + i,
    color: 'orange',
    backgroundColor: 'rgba(88, 41, 187, .05)',
  };
}

export const OUTCOME_RESULT_OPTIONS = [
  [ OutcomeResultsEnum.WIN, 'Win', 'green' ],
  [ OutcomeResultsEnum.LOSE, 'Lose', 'red' ],
  [ OutcomeResultsEnum.VOID, 'Void', 'violet' ],
  [ OutcomeResultsEnum.NO_RESULT, 'No Result', 'grey' ],
  [ OutcomeResultsEnum.SET_PLACE, 'Set Place', 'orange' ]
];

export const EVENT_TYPES_OPTIONS = [
  { value: 'GAME', text: 'Game' },
  { value: 'RANK', text: 'Rank' }
];

export const BEST_OF_SETS_OPTIONS = [
  { value: 1, text: '1' },
  { value: 3, text: '3' },
  { value: 5, text: '5' }
];

export const TIEBREAK_OPTIONS = [
  { text: 'Regular Set Tiebreak', value: 'TIE_BREAK_REGULAR' },
  { text: 'Advantage Set', value: 'TIE_BREAK_ADVANTAGE' },
  { text: '10 points Set Tiebreak', value: 'TIE_BREAK_10_POINTS' },
  { text: 'Set Tiebreak at 12-12', value: 'TIE_BREAK_12_12' },
  { text: 'Supertiebreak', value: 'TIE_BREAK_SUPER' }
];

export const ROWS_PER_PAGE_OPTIONS = [
  { text: '10', value: '10' },
  { text: '25', value: '25' },
  { text: '50', value: '50' }
];

export const ASCENDING = 'ascending';
export const ASC = 'ASC';
export const DESCENDING = 'descending';
export const DESC: 'DESC' = 'DESC';
export const DATE_FORMAT_DATE_TIME = 'YYYY-MM-DD HH:mm';
export const DATE_FORMAT_3_DIGIT_MONTH_TIME = 'DD MMM YYYY HH:mm';
export const COLOR_GREEN = '#21ba45';
export const COLOR_RED = '#c8512c';
export const COLOR_ORANGE = '#f2711c';
export const COLOR_PINK = '#e03997';
export const COLOR_LIGHT_GREY = 'rgba(191,191,191,.87)';

export const SORT_DIC = {
  [ASCENDING]: ASC,
  [ASC]: ASCENDING,
  [DESCENDING]: DESC,
  [DESC]: DESCENDING,
}

export const EVENT_PATH_TREE_COLORS = {
  sport: COLOR_GREEN,
  region: COLOR_PINK,
  league: COLOR_ORANGE,
};

export const NO_DATA = 'No data';
export const TABLE_TENNIS_SPORT_ID = '1900';
export const TENNIS_SPORT_ID = '239';
export const VOLLEYBALL_ID = '1200';
export const BEACH_VOLLEYBALL_ID = '1250';

export enum BetStatusesEnum {
  BET_STATUS_PENDING = 'BET_STATUS_PENDING',
  BET_STATUS_WIN = 'BET_STATUS_WIN',
  BET_STATUS_CASH_OUT = 'BET_STATUS_CASH_OUT',
  BET_STATUS_LOSE = 'BET_STATUS_LOSE',
  BET_STATUS_FAIL = 'BET_STATUS_FAIL',
  BET_STATUS_TRADER_VOID = 'BET_STATUS_TRADER_VOID',
  BET_STATUS_VOID = 'BET_STATUS_VOID',
  BET_STATUS_SETTLE_ERROR = 'BET_STATUS_SETTLE_ERROR',
  BET_STATUS_PORTAL_MANUAL_PAYOUT = 'BET_STATUS_PORTAL_MANUAL_PAYOUT',
}

export enum BetsSortByEnum {
  BET_SORT_DATE = 'BET_SORT_DATE',
  BET_SORT_STAKE = 'BET_SORT_STAKE',
  BET_SORT_STAKE_LOCAL = 'BET_SORT_STAKE_LOCAL',
  BET_SORT_PAYOUT = 'BET_SORT_PAYOUT',
  BET_SORT_PAYOUT_LOCAL = 'BET_SORT_PAYOUT_LOCAL',
  BET_SORT_PRICE = 'BET_SORT_PRICE',
}

export const BetsTablePageType = {
  LARGE_MULTIPLE: 'LARGE_MULTIPLE',
  LARGE_SINGLE: 'LARGE_SINGLE',
  BETS_HISTORY_WITH_LIVE_MODE: 'BETS_HISTORY_WITH_LIVE_MODE',
  OUTCOME_BETS: 'OUTCOME_BETS',
};

export const BET_STATUSES = {
  BET_STATUS_PENDING: {
    label: 'Pending',
    color: 'grey',
  },
  BET_STATUS_WIN: {
    label: 'Win',
    color: 'green',
  },
  BET_STATUS_CASH_OUT: {
    label: 'Cash out',
    color: 'red',
  },
  BET_STATUS_LOSE: {
    label: 'Lose',
    color: 'red',
  },
  BET_STATUS_FAIL: {
    label: 'Rejected',
    color: 'brown',
  },
  BET_STATUS_TRADER_VOID: {
    label: 'Trader Void',
    color: 'purple',
  },
  BET_STATUS_VOID: {
    label: 'Void',
    color: 'purple',
  },
  BET_STATUS_SETTLE_ERROR: {
    label: 'Settle Error',
    color: 'red',
  },
  BET_STATUS_PORTAL_MANUAL_PAYOUT: {
    label: 'Settled in Portal',
    color: 'olive',
  },
};

export const BET_REJECT_REASONS = {
  BET_REASON_OWALLET: 'Balance',
  BET_REASON_LIMITS: 'Limits',
  BET_REASON_RESTRICTIONS: 'Bet restriction',
  BET_REASON_PRICE_CHANGED: 'Price change',
  BET_REASON_OTHER: 'Other',
};

export const BET_REJECT_REASONS_DROPDOWN_OPTIONS = Object.keys(BET_REJECT_REASONS)
  .map(value => ({
    value,
    text: BET_REJECT_REASONS[value],
  })) as Array<{ value: string; text: string }>;

export const BET_STATUS_DROPDOWN_OPTIONS = Object.keys(BET_STATUSES)
  .filter(el => el !== 'BET_STATUS_PORTAL_MANUAL_PAYOUT')
  .map(value => ({
    value,
    text: BET_STATUSES[value].label,
  })) as Array<{ value: string; text: string }>;

export const BOOK_TYPES_ENUM = {
  BOOKTYPE_ALL: 'BOOKTYPE_ALL',
  BOOKTYPE_PRE_MATCH: 'BOOKTYPE_PRE_MATCH',
  BOOKTYPE_LIVE: 'BOOKTYPE_LIVE',
};

export const BOOK_TYPES = {
  [BOOK_TYPES_ENUM.BOOKTYPE_PRE_MATCH]: 'Prematch',
  [BOOK_TYPES_ENUM.BOOKTYPE_LIVE]: 'Live',
};

export const BOOK_TYPES_OPTIONS = dictToOptions(BOOK_TYPES);

export const MANUAL_MODE_ENUM = {
  manualCreated: 'manualCreated',
  manualTrading: 'manualTrading',
};

export const MANUAL_MODE_TYPES = {
  [MANUAL_MODE_ENUM.manualTrading]: 'Manual Trading',
  [MANUAL_MODE_ENUM.manualCreated]: 'Manual Created',
};

export const MANUAL_MODE_OPTIONS = dictToOptions(MANUAL_MODE_TYPES);

export const IRM_STATUSES_ENUM = {
  showInIrm: 'showInIrm',
  hideInIrm: 'hideInIrm',
};

export const IRM_STATUSES_TYPES = {
  [IRM_STATUSES_ENUM.showInIrm]: 'Show',
  [IRM_STATUSES_ENUM.hideInIrm]: 'Hide',
};

export const IRM_STATUSES_OPTIONS = dictToOptions(IRM_STATUSES_TYPES);

export const BETBUILDER_TYPE_ENUM = {
  isBetbuilder: 'isBetbuilder',
};

export const BETBUILDER_TYPES = {
  [BETBUILDER_TYPE_ENUM.isBetbuilder]: 'Betbuilder',
};

export const BETBUILDER_TYPE_OPTIONS = dictToOptions(BETBUILDER_TYPES);

export const MARKET_BETS_OPTIONS_TYPE_ENUM = {
  withBets: 'withBets',
};

export const MARKET_BETS_OPTIONS_TYPES = {
  [MARKET_BETS_OPTIONS_TYPE_ENUM.withBets]: 'With Bets',
};

export const MARKET_BETS_OPTIONS = dictToOptions(MARKET_BETS_OPTIONS_TYPES);

/** Filters */
export const USER_ID_FILTER: 'userId' = 'userId';
export const EVENT_ID_FILTER: 'eventId' = 'eventId';
export const BET_TYPE_FILTER: 'betType' = 'betType';
export const BET_STATUSES_FILTER: 'betStatuses' = 'betStatuses';
export const STAKE_FILTER: 'stake' = 'stake';
export const SPORT_FILTER: 'sports' = 'sports';
export const MIN_STAKE_FILTER: 'minStake' = 'minStake';
export const MAX_STAKE_FILTER: 'maxStake' = 'maxStake';
export const BOOK_TYPE_FILTER: 'bookType' = 'bookType';
export const BOOK_TYPES_FILTER: 'bookTypes' = 'bookTypes';
export const BET_ACCEPTED_FILTER: 'betAccepted' = 'betAccepted';
export const PERIOD_FILTER: 'period' = 'period';
export const MARKET_BETS_FILTER: 'marketBets' = 'marketBets';
export const MARKET_STATUSES_FILTER: 'marketStatuses' = 'marketStatuses';
export const MANUAL_MODES_FILTER: 'manualModes' = 'manualModes';
export const IRM_STATUS_FILTER: 'irmStatuses' = 'irmStatuses';
export const MARKET_TYPE_FILTER: 'marketType' = 'marketType';
export const MARKET_PERIOD_FILTER: 'marketPeriod' = 'marketPeriod';
export const HIDE_TEST_ACCOUNTS_FILTER: 'hideTestAccounts' = 'hideTestAccounts';
export const BET_ID_FILTER: 'betId' = 'betId';
export const BET_REJECTED_REASON_FILTER: 'betRejectReasons' = 'betRejectReasons';
export const ORIGIN_IDS_FILTER: 'originIds' = 'originIds';
export const ORIGIN_ID_FILTER: 'originId' = 'originId';
export const FACTOR_GROUP_FILTER: 'factorGroupIds' = 'factorGroupIds';
export const SHOW_ONLY_OUTRIGHTS_FILTER: 'showOnlyOutrights' = 'showOnlyOutrights';
export const SORT_BY_FILTER_QUERY: 'sortBy' = 'sortBy';
export const SORT_DIR_FILTER_QUERY: 'sortDir' = 'sortDir';

export const MARKET_STATUSES_UPDATE_DATA = [
  [ MARKET_STATUS_ENUM.OPEN, MARKET_STATUSES.OPEN, 'Open', 'Open' ],
  [ MARKET_STATUS_ENUM.SUSPENDED, MARKET_STATUSES.SUSPENDED, 'Suspend', 'Suspend' ],
  [ MARKET_STATUS_ENUM.CLOSED, MARKET_STATUSES.CLOSED, 'Close', 'Close' ],
];
export const BETBUILDER_TYPE_FILTER: 'betbuilderTypeField' = 'betbuilderTypeField';

export const UNKNOWN_PLAYER_FACTOR_GROUP = {
  id: 'Unknown',
  description: 'Unknown FG',
  shortDescription: 'Unknown FG',
  color: 'lightgrey',
};

export const FILTERS_FOR_MANUAL_EVENTS = [
  MARKET_BETS_FILTER,
  MARKET_STATUSES_FILTER,
  BETBUILDER_TYPE_FILTER,
  BOOK_TYPES_FILTER,
  MANUAL_MODES_FILTER,
  IRM_STATUS_FILTER
];

export const FILTERS_FOR_FEED_EVENTS = [
  MARKET_BETS_FILTER,
  MARKET_STATUSES_FILTER,
  BETBUILDER_TYPE_FILTER,
  BOOK_TYPES_FILTER,
  MANUAL_MODES_FILTER
];

export const TRADER_PERM_UTILITIES = 'TRADER_PERM_UTILITIES';
export const TRADER_PERM_EVENT_EDITOR = 'TRADER_PERM_EVENT_EDITOR';
export const TRADER_PERM_PLACE_BET = 'TRADER_PERM_PLACE_BET';
export const TRADER_PERM_ALL = 'TRADER_PERM_ALL';

export const ORIGIN_ID_DEFAULT = '1';

export const KEY_CODE_OF_ENTER = 13;
export const KEY_ENTER = 'Enter';
export const KEY_TAB = 'Tab';

export const DEFAULT_OUTCOME = {
  description: '',
  price: '',
  result: OutcomeResultsEnum.NO_RESULT,
  ordinalPosition: null,
};

export const MARKET_TYPE_GROUP_OUTRIGHT = '4';
export const MARKET_TYPE_GROUP_PLAYER_PERFORMANCE = '9';

export const SPORT_ID_HORSE_RASING = '364';
export const SPORT_ID_GREYHOUNDS = '1000';

export const MINIMAL_MARGIN = 1;

export const LINK_BET_RESTRICTIONS = 'bet-restrictions';
export const LINK_BETS = 'bets';
export const LINK_MARKETS = 'markets';
export const LINK_IRM = 'irm';

export const ROW_COLOR_FIRST = 'first';
export const ROW_COLOR_SECOND = 'second';

export const MARKET_TYPE_ID = 'marketTypeId';
export const PERIOD_ID = 'periodId';
export const CUTOFF_TIME = 'cutoffTime';
export const STATUS = 'status';
export const OUTCOMES = 'outcomes';

export const DESCRIPTION = 'description';

export const ORIGIN_DEFAULT_ID = 1;

export const TREE_LEVELS = {
  HEADER_LEVEL: 1,
  SECOND_LEVEL: 2,
  THIRD_LEVEL: 3,
};

export const MIN_SCOREBOARD_SIZE = 850;
export const MIN_MARKETS_BLOCK_SIZE = 850;
export const MAX_MARKETS_BLOCK_SIZE = 1100;
export const PADDING_SIZE = 20;
export const HORIZONTAL_POSITION_SIZE = MIN_SCOREBOARD_SIZE + MIN_MARKETS_BLOCK_SIZE + PADDING_SIZE * 4;

export const IRM_NULL_PARENT_ID = '0';

export const START_DATE_PARAM = 'startDate';
export const END_DATE_PARAM = 'endDate';

export const FILTER_INTERVALS = {
  hour: { text: '1 hour', value: '1h' },
  day: { text: '24 hours', value: '24h' },
  threeDays: { text: '72 hours', value: '72h' },
  week: { text: '7 days', value: '7d' },
  month: { text: '1 month', value: '1month' },
  threeMonth: { text: '3 months', value: '3months' },
  year: { text: '1 year', value: '1y' },
  all: { text: 'All time', value: 'all' },
  custom: { text: 'Custom', value: 'custom' },
};

export const STATUS_UNKNOWN = 'UNKNOWN';
export const CLOCK_STATUS_UNKNOWN = 'CLOCK_STATUS_UNKNOWN';
export const NOT_STARTED = 'NOT_STARTED';

export const CHANGE_LOCAL_PRICE_INDEX_ACTION = 'change_local_price_index';
export const CHANGE_CURRENT_PRICE_INDEX_ACTION = 'change_current_price_index';
export const CHANGE_CURRENT_AND_LOCAL_PRICES_INDEX_ACTION = 'change_current_and_local_price_index';
export const RESET_DELTA_INDEX_ACTION = 'reset_delta';
export const ADD_PRICES_WITH_INDEX_ACTION = 'add_price';
export const SAVE_PRICES_ACTION = 'save_prices';
export const REWRITE_PRICES = 'rewrite_prices'

export const PLAYER_TYPE = 'PLAYER';
export const TEAM_TYPE = 'TEAM';

export const DEFAULT_MARKET = {
  status: MARKET_STATUS_ENUM.SUSPENDED,
  hideMode: false,
  outcomes: [
    {
      description: '',
      price: '',
      opponentId: null,
    }
  ],
  description: '',
  marketTypeId: '',
  periodId: '',
  cutoffTime: '',
};

export const CURRENCY_ID_USD = '1'
export const LIMIT_MIN_STAKE = 'ORIGIN_MIN_STAKE';

export const ORIGIN_COOKIE_KEY = 'originId'

export const SHORT_POLL_INTERVAL = 3000
export const LONG_POLL_INTERVAL = 10000

export const QUERY_STATUS = {
  PENDING: 'pending',
  ERROR: 'error',
  SUCCESS: 'success'
}

export const SPORT_ID = {
  FOOTBALL: '240'
}

export const LINK_PERSONAL_LIMITS = 'personal-limits'
