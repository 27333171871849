import { useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';

import { UpdateButtons } from './UpdateButtons';
import { Loading } from '../../components/UI/Loading';
import { usePersonalLimitGroupMutation } from '../../react-query/mutations';
import { KEY_PERSONAL_LIMIT_GROUP, usePersonalLimitGroup } from '../../react-query/query';
import { LeagueLimitGroup, UserOutletContextType } from '../../types';
import { LimitGroupsEditor } from '../LimitGroups/LimitGroups';

export const PersonalLimitsTable = () => {
  const { userId } = useOutletContext<UserOutletContextType>();

  const [ limits, setLimits ] = useState<LeagueLimitGroup[]>([])
  const [ initLimits, setInitLimits ] = useState([])
  const [ enabledLimits, setEnabledLimits ] = useState<boolean>(false)
  const queryClient = useQueryClient();

  const {
    data,
    isFetching,
    error,
    refetch
  } = usePersonalLimitGroup({userId})

  useEffect(() => {
    if(!isFetching){
      setLimits(data?.limits)
      setInitLimits(data?.limits)
      setEnabledLimits(data?.enabled)
    }
  }, [ isFetching, data ])

  const { mutate: submitMutate } = usePersonalLimitGroupMutation({
    onSuccess: async () => {
      toast.success('Limits has been updated.');
      await queryClient.invalidateQueries({ queryKey: [ KEY_PERSONAL_LIMIT_GROUP, userId ] });
    },
    onError: error => {
      toast.error([ 'There was an error while updating limits.', error.message ].join('\n'));
    },
  });

  const { mutate: enabledMutate } = usePersonalLimitGroupMutation({
    onSuccess: async () => {
      toast.success('Personal limits were enabled');
    },
    onError: error => {
      toast.error([ 'There was an error while enabling limits.', error.message ].join('\n'));
    },
  });


  const submit = () => {
    submitMutate({
      userId: userId,
      limitGroups: limits,
      enabled: enabledLimits
    });
  }

  const changeEnabledStatus = () => {
    enabledMutate({
      userId: userId,
      limitGroups: initLimits,
      enabled: !enabledLimits
    });
    setEnabledLimits(!enabledLimits)
  }

  return <>
    {
      isFetching
        ? <Loading/>
        : <>
          <LimitGroupsEditor
            limitState={limits}
            setLimitState={setLimits}
          />
          <UpdateButtons
            changeEnabledStatus={changeEnabledStatus}
            submit={submit}
            disabled={isEqual(limits, initLimits)}
            setEnabledLimits={setEnabledLimits}
            enabledLimits={enabledLimits}
          />
        </>
    }
  </>
}
