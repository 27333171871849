import React from 'react';

import moment from 'moment';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { Table, Header, Grid } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import { NewLeaguesFilters } from './NewLeaguesFilters';
import styles from './NewLeaguesReport.module.css';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { TableSkeleton } from '../../components/UI/TableSkeleton';
import { EVENT_TREE_PATH } from '../../constants';
import { useQueryState, useSortQuery } from '../../hooks-and-global-states/history-hooks';
import { DateTime } from '../../hooks-and-global-states/time-hooks';

export const HEADER_CELLS = [
  { name: 'id', label: 'ID', sortable: true },
  { name: 'description',label: 'Description', sortable: true },
  { name: 'date',label: 'Created Date', sortable: true },
  { name: 'sportDescription',label: 'Sport', sortable: true },
  { name: 'regionDescription',label: 'Region', sortable: true }
];

export const NewLeaguesReport = () => {
  return (
    <ErrorBoundary>
      <NewLeaguesReportComponent />
    </ErrorBoundary>
  );
};

const NewLeaguesReportComponent = () => {
  const { queryState } = useQueryState();
  const { startDateTo, startDateFrom, sports } = queryState;

  const { sortBy, setSort, sortDirForSemanticTable, sortDir } = useSortQuery({
    sortBy: 'date',
  });

  const { data, isFetching } = useAxiosRequest<{
    result: Array<{
      id: string;
      description: string;
      regionDescription: string;
      sportDescription: string;
      date: string;
    }>;
  }>(
    React.useMemo(() => {
      const to = startDateTo || moment().utc().format();
      const from = startDateFrom || moment(to).utc().subtract(1, 'days').format();

      return {
        // TODO to constant
        url: '/v1/DealerAPI/leagues/findByDate',
        params: {
          sportIds: sports,
          from,
          to,
          sortBy,
          sortDir
        },
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      };
    }, [ startDateTo, startDateFrom, sports, sortBy, sortDir ])
  );

  return (
    <div className={styles.wrapper}>
      <Header>New Leagues</Header>

      <Grid>
        <NewLeaguesFilters/>
        <Grid.Column width={13}>
          <Table structured celled sortable>
            <Table.Header>
              <Table.Row>
                {HEADER_CELLS.map(({ name, label, sortable }) => (
                  <Table.HeaderCell
                    key={name}
                    sorted={sortBy === name ? sortDirForSemanticTable : null}
                    onClick={
                      sortable
                        ? () => setSort(name)
                        : undefined
                    }
                  >
                    {label}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {isFetching || !data ? (
                <TableSkeleton
                  rows={5}
                  columns={5}
                  rowHeight={1}
                />
              ) : (
                <>
                  {
                    data?.result?.map(({ id, description, date, sportDescription, regionDescription }) => (
                      <Table.Row key={id}>
                        <Table.Cell>{id}</Table.Cell>
                        <Table.Cell>
                          <Link
                            to={`${EVENT_TREE_PATH}/league/${id}`}
                            className="as-link"
                          >
                            {description}
                          </Link>
                        </Table.Cell>
                        <Table.Cell>
                          <DateTime>{date}</DateTime>
                        </Table.Cell>
                        <Table.Cell>{sportDescription}</Table.Cell>
                        <Table.Cell>{regionDescription}</Table.Cell>
                      </Table.Row>
                    ))
                  }
                  <Table.Row>
                    <Table.Cell colSpan={5}>
                      {`Total number of leagues: ${data?.result?.length}`}
                    </Table.Cell>
                  </Table.Row>
                </>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </div>
  );
};
