import React from 'react';

import { Label } from 'semantic-ui-react';

import { usePlayerFactorGroupById } from '../../utils';

export const PlayerFactorGroupLabel = ({
  userFactorGroupId,
  simple,
  isSpecial = false,
}:{
  userFactorGroupId: string;
  simple?: boolean;
  isSpecial?: boolean
}) => {
  const playerFactorGroup = usePlayerFactorGroupById(userFactorGroupId);

  if (!playerFactorGroup) {
    return null;
  }

  if (simple) {
    return (
      <div>
        {playerFactorGroup.shortDescription}
      </div>
    );
  }

  const style: Record<string, string> =  !isSpecial ? {
    backgroundColor: playerFactorGroup.color,
    border: '1px solid gray',
  } : {
    backgroundColor: '#ff4a4a',
    border: '1px solid gray',
  }

  return (
    <Label
      horizontal
      size="mini"
      style={style}
    >
      {!isSpecial ? playerFactorGroup.shortDescription : 'Special'}
    </Label>
  );
};
