import React from 'react';

import { Grid } from 'semantic-ui-react';

// eslint-disable-next-line max-len
import { QueryStateStartAndEndDate } from '../../components/search-and-filters-components/QueryStateFilterByPeriod';
import {
  QueryStateSportsDropdownFilter
} from '../../components/search-and-filters-components/QueryStateSportsDropdownFilter';

const PARENT_ID = 'new-leagues--'

export const NewLeaguesFilters = () => {
  return (
    <Grid.Column width={3}>
      <QueryStateStartAndEndDate
        id={PARENT_ID}
        startDateParam="startDateFrom"
        endDateParam="startDateTo"
      />
      <QueryStateSportsDropdownFilter
        id={PARENT_ID}
      />
    </Grid.Column>
  )
}
