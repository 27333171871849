import { useQueryState } from '../../hooks-and-global-states/history-hooks';
import { SportsDropdown } from '../../pages/EventPaths/SportsDropdown';

export const QueryStateSportsDropdownFilter = (
  {
    id
}) => {

  const { pendingQueryState, setQueryState } = useQueryState();

  const onChange = (data: string[]) => {
    setQueryState('sports', data)
  };

  return (
    <SportsDropdown
      id={id + 'sport-dropdown'}
      value={pendingQueryState['sports']}
      onChange={onChange}
      placeholder="Sports"
      fluid
      multiple
    />
  );
};
